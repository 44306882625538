import { Box, Button, Group, List, Loader, LoadingOverlay, Overlay, Text } from '@mantine/core';
import { MdOutlineArrowBack } from 'react-icons/md';
import { useForm, yupResolver } from '@mantine/form';
import * as yup from 'yup';
import FileUpload from '../../common/FileUpload';
import { apiFileSubmit, apiUpload } from '../../../api/ApiServices';
import { useEffect, useState } from 'react';
import { notifications } from '@mantine/notifications';

const UploadDoc = ({ onNextStep, onPrevStep }: any) => {
  // const [step, setStep] = useState(2);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [submit, setSubmit] = useState(false);

  const [step, setStep] = useState(() => {
    const savedStep = localStorage.getItem('currentStep');
    return savedStep ? parseInt(savedStep, 10) : 2;
  });

  useEffect(() => {
    localStorage.setItem('currentStep', step.toString());
  }, [step]);

  const schema = yup.object().shape({
    certificateOfIncorporation: yup
      .mixed()
      .required('Certificate Of Incorporation is required ') as yup.MixedSchema<File | null>,
    memorandumAssociation: yup
      .mixed()
      .required('Memorandum Association is required ') as yup.MixedSchema<File | null>,
    commencementOfBusiness: yup
      .mixed()
      .required('Certificate Of Commencement is required ') as yup.MixedSchema<File | null>,
    boardResolution: yup
      .mixed()
      .required('Board Resolution is required ') as yup.MixedSchema<File | null>,
    directorsParticular: yup
      .mixed()
      .required('directorsParticular is required ') as yup.MixedSchema<File | null>,
    shareAllotment: yup.mixed().required('CO2 is required ') as yup.MixedSchema<File | null>,
    partnershipDeed: yup
      .mixed()
      .required('Partnership Deed is required ') as yup.MixedSchema<File | null>,
    utilityBill: yup.mixed().required('Utility Bill is required ') as yup.MixedSchema<File | null>,
    scumlReg: yup.mixed().required('Scuml File is required ') as yup.MixedSchema<File | null>,
    nipcReg: yup.mixed().required('NIPC is required ') as yup.MixedSchema<File | null>,
  });

  const form = useForm({
    initialValues: {
      certificateOfIncorporation: null,
      memorandumAssociation: null,
      commencementOfBusiness: null,
      boardResolution: null,
      directorsParticular: null,
      shareAllotment: null,
      partnershipDeed: null,
      utilityBill: null,
      scumlReg: null,
      nipcReg: null,
    },

    validate: yupResolver(schema),
  });

  const [payload, setPayload] = useState({});
  const FILE_SIZE_LIMIT = 5 * 1024 * 1024;
  const SUPPORTED_FILE_FORMATS = [
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ];
  const MAX_FILE_COUNT = 5;
  // Function to handle file upload to link
  const fileUpload = async (uploadFiles: File[], key: any) => {
    const files = uploadFiles.length ? uploadFiles : null;

    if (files === null) {
      form.setValues({ [key]: null });
      return;
    }
    // File size and type validation
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file.size > FILE_SIZE_LIMIT) {
        setError(
          notifications.show({
            title: 'File size too large',
            color: 'red',
            autoClose: 20000,
            message: `File size of ${file.name} exceeds the limit of 5MB.`,
          })
        );
        return;
      }
      // File type validation
      if (!SUPPORTED_FILE_FORMATS.includes(file.type)) {
        setError(
          notifications.show({
            title: 'Unsupported file format',
            color: 'red',
            autoClose: 20000,
            message: `Unsupported file format for ${file.name}. Only PDF, DOC, DOCX files are allowed.`,
          })
        );
        return;
      }
    }

    // Maximum file count check
    if (files.length > MAX_FILE_COUNT) {
      setError(
        notifications.show({
          title: 'Maximum number of files exceeded',
          color: 'red',
          autoClose: 20000,
          message: `You can upload a maximum of ${MAX_FILE_COUNT} files.`,
        })
      );
      return;
    }
    const formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      formData.append('files', file);
    }

    try {
      setIsLoading(true);
      const { data } = await apiUpload(formData);
      const links = data.links;
      form.setValues({ [key]: files });
      setPayload({ ...payload, [key]: data.links });
      return links;
    } catch (error: any) {
      setError(
        notifications.show({
          title: 'Something went wrong',
          color: 'red',
          message:
            error?.data?.message || error.message || 'An error occurred, please try again later.',
        })
      );
    } finally {
      setIsLoading(false);
    }
  };

  // file submit save and continue
  const submitLinks = async (event: any, links = payload) => {
    try {
      setSubmit(true);
      const id = localStorage.getItem('registeredId') || '';
      const resp = await apiFileSubmit(id, links);
      console.log(resp);
      setSubmit(false);

      onNextStep();
    } catch (error: any) {
      setError(
        notifications.show({
          title: 'Something went wrong',
          color: 'red',
          message:
            error?.data?.message || error.message || 'An error occurred, please try again later.',
        })
      );
    } finally {
      setSubmit(false);
    }
  };

  return (
    <>
      <Box pos="relative">
        <LoadingOverlay
          zIndex={1000}
          visible={isLoading}
          overlayProps={{ radius: 'sm', blur: 1 }}
        />

        <form onSubmit={form.onSubmit((e) => submitLinks(e))}>
          <Box mb={50} mt={20}>
            <Text ta="left" fw={500}>
              Provide us with the following Documents
            </Text>
            <Text ta="left" size="13px" mt={10}>
              Make sure this is the same information as on your Certificate of Incorporation and
              Registration so we can verify it later on.
            </Text>
          </Box>
          <Box>
            <Text ta="left" size="14px" fw={500}>
              Upload Certificate of Incorporation and Registration
            </Text>
            <Text ta="left" size="11px" mt={8}>
              For verification purposes, please upload a high-quality copy of your Certificate of
              Incorporation and Registration showing all relevant details.
            </Text>

            <FileUpload
              {...form.getInputProps('certificateOfIncorporation')}
              onChange={(files) => fileUpload(files, 'certificateOfIncorporation')}
              disabled={isLoading}
            />
          </Box>
          <Box mt={50}>
            <Text ta="left" size="14px" fw={500}>
              Upload Memorandum Association and Articles of Association
            </Text>
            <Text ta="left" size="11px" mt={8}>
              To ensure we can accurately process your Upload Memorandum Association and Articles of
              Association, please upload a well-lit copy where all text and elements are easily
              readable. Avoid blurry or folded documents.
            </Text>

            <FileUpload
              {...form.getInputProps('memorandumAssociation')}
              onChange={(files) => fileUpload(files, 'memorandumAssociation')}
              disabled={isLoading}
            />
          </Box>
          <Box mt={50}>
            <Text ta="left" size="14px" fw={500}>
              Upload Certificate of commencement of business (if applicable)
            </Text>
            <Text ta="left" size="11px" mt={8}>
              To help us verify your business, please upload a good quality copy of your Certificate
              of Commencement of Business. Make sure the image is bright and all details are easy to
              see.
              <FileUpload
                {...form.getInputProps('commencementOfBusiness')}
                onChange={(files) => fileUpload(files, 'commencementOfBusiness')}
                disabled={isLoading}
              />
            </Text>
          </Box>
          <Box mt={50}>
            <Text ta="left" size="14px" fw={500}>
              Upload Board Resolution Documents
            </Text>
            <Text ta="left" size="13px" mt={12}>
              This document should include:
            </Text>
            <List type="ordered" size="xs" mt={10}>
              <List.Item>Representatives are mandated to open the acct</List.Item>
              <List.Item>
                Names of the representatives or signatories and their positions in the company.{' '}
                <br /> For example president, secretary, member etc.
              </List.Item>
              <List.Item>
                Mandate on operating the account (indicating if it is 1 or 2 signatories)
              </List.Item>
              <List.Item>Should be signed by 2 directors in the board</List.Item>
            </List>

            <FileUpload
              {...form.getInputProps('boardResolution')}
              onChange={(files) => fileUpload(files, 'boardResolution')}
              disabled={isLoading}
            />
          </Box>
          <Box mt={50}>
            <Text ta="left" size="14px" fw={500}>
              Upload Form CO7 (Particulars of the directors)
            </Text>
            <Text ta="left" size="11px" mt={8}>
              Please upload a copy of your recent utility bill, ensuring all information, including
              your address and account number, is clearly visible.
            </Text>

            <FileUpload
              {...form.getInputProps('directorsParticular')}
              onChange={(files) => fileUpload(files, 'directorsParticular')}
              disabled={isLoading}
            />
          </Box>
          <Box mt={50}>
            <Text ta="left" size="14px" fw={500}>
              Upload Form CO2 (Allotment of shares)
            </Text>
            <Text ta="left" size="11px" mt={8}>
              Please upload a copy of your recent utility bill, ensuring all information, including
              your address and account number, is clearly visible.
            </Text>

            <FileUpload
              {...form.getInputProps('shareAllotment')}
              onChange={(files) => fileUpload(files, 'shareAllotment')}
              disabled={isLoading}
            />
          </Box>
          <Box mt={50}>
            <Text ta="left" size="14px" fw={500}>
              Upload Partnership Deed (where applicable)
            </Text>
            <Text ta="left" size="11px" mt={8}>
              Please upload a copy of your recent utility bill, ensuring all information, including
              your address and account number, is clearly visible.
            </Text>

            <FileUpload
              {...form.getInputProps('partnershipDeed')}
              onChange={(files) => fileUpload(files, 'partnershipDeed')}
              disabled={isLoading}
            />
          </Box>
          <Box mt={50}>
            <Text ta="left" size="14px" fw={500}>
              Upload Utility bill
            </Text>
            <Text ta="left" size="11px" mt={8}>
              Please upload a copy of your recent utility bill, ensuring all information, including
              your address and account number, is clearly visible.
            </Text>

            <FileUpload
              {...form.getInputProps('utilityBill')}
              onChange={(files) => fileUpload(files, 'utilityBill')}
              disabled={isLoading}
            />
          </Box>
          <Box mt={50}>
            <Text ta="left" size="14px" fw={500}>
              Upload Evidence of Registration with Special Control Unit against Money Laundering
              (SCUML) (where applicable)
            </Text>
            <Text ta="left" size="13px" mt={12}>
              This document should include:
            </Text>
            <List type="ordered" size="xs" mt={10}>
              <List.Item>Representatives are mandated to open the acct</List.Item>
              <List.Item>
                Names of the representatives or signatories and their positions in the company.{' '}
                <br /> For example president, secretary, member etc.
              </List.Item>
              <List.Item>
                Mandate on operating the account (indicating if it is 1 or 2 signatories)
              </List.Item>
            </List>

            <FileUpload
              {...form.getInputProps('scumlReg')}
              onChange={(files) => fileUpload(files, 'scumlReg')}
              disabled={isLoading}
            />
          </Box>
          <Box mt={50}>
            <Text ta="left" size="14px" fw={500}>
              Upload Evidence of Registration with Nigerian Investment Promotion Council (NIPC)
              (where applicable)
            </Text>

            <Text ta="left" size="11px" mt={8}>
              Please ensure a clear, high-resolution copy of the signatory's ID is uploaded, showing
              all details clearly. This ensures a smooth and timely verification process.
            </Text>

            <FileUpload
              {...form.getInputProps('nipcReg')}
              onChange={(files) => fileUpload(files, 'nipcReg')}
              disabled={isLoading}
            />
          </Box>
          <Group justify="center" mt="xl">
            <Button
              bg="transparent"
              color="#0798D0"
              w={180}
              radius={12}
              mx={10}
              h={37}
              leftSection={<MdOutlineArrowBack size={16} />}
              variant="light"
              type="button"
              component="a"
              onClick={onPrevStep}>
              Back
            </Button>
            <Button
              variant="gradient"
              gradient={{ from: '#24E3F2', to: '#0798D0', deg: 90 }}
              w={180}
              radius={12}
              mx={10}
              type="submit"
              disabled={submit}>
              {submit ? <Loader size={20} /> : 'Save and Continue'}
            </Button>
          </Group>
        </form>
      </Box>
    </>
  );
};

export default UploadDoc;
