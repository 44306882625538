import { Anchor, Box, Button, Center, Group, List, Loader, Text, TextInput } from '@mantine/core';
import { useEffect, useState } from 'react';
import { apiEnable2fa, apiVerify2fa } from '../../../api/ApiServices';
import * as yup from 'yup';
import { useForm } from '@mantine/form';
import { yupResolver } from 'mantine-form-yup-resolver';
import { notifications } from '@mantine/notifications';
import { jwtDecode } from 'jwt-decode';

const Authenticator = () => {
  const [error, setError] = useState('');
  const [qrCode, setQrCode] = useState<{ qrUrl: string; secret: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState<string>('');
  const [showAuthenticationCode, setShowAuthenticationCode] = useState(false);
  const [showQRCode, setShowQRCode] = useState(true);
  const schema = yup.object().shape({
    token: yup.string().min(6, 'code should be at least 6 digit').required('Code is required'),
  });

  const form = useForm({
    initialValues: {
      token: '',
    },
    validate: yupResolver(schema),
  });

  // verify code
  const verifyCode = async (params: any) => {
    try {
      setLoading(true);
      const payload = {
        ...params,
        email,
      };

      //836 091 -> 836091
      payload.token = payload.token.replace(/\D/g, '');

      if (payload.token.length !== 6) {
        notifications.show({
          title: 'Verify two factor authentication',
          color: 'red',
          message: 'Code must be six digits',
        });
        setLoading(false);
        return;
      }

      const resp = await apiVerify2fa(payload);
      setError(
        notifications.show({
          title: 'Successful',
          color: 'green',
          message: 'Successful',
        })
      );
      setTimeout(() => {
        window.location.href = '/login';
      }, 4000);
      setLoading(false);
    } catch (error: any) {
      notifications.show({
        title: 'Verify two factor authentication',
        color: 'red',
        message:
          error?.data?.message || error?.message || 'An error occurred, please try again later.',
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const token = queryParameters.get('token');
    // get email from  query
    if (token) {
      const decoded = jwtDecode(token) as { email: string };
      if (decoded.email) {
        setEmail(decoded.email);
        enable2fa(decoded.email);
      }
    }
  }, []);

  // enable 2fa
  const enable2fa = async (email: string) => {
    try {
      setIsLoading(true);
      const payload = {
        email,
      };
      const resp = await apiEnable2fa(payload);
      setQrCode(resp.data);
    } catch (error: any) {
      notifications.show({
        title: 'Enable two factor authentication',
        color: 'red',
        message:
          error?.data?.message || error?.message || 'An error occurred, please try again later.',
      });
    } finally {
      setIsLoading(false);
    }
  };

  // auth code

  const handleShowAuthenticationCode = () => {
    setShowAuthenticationCode(true);
  };

  const handleShowScanCode = () => {
    setShowAuthenticationCode(false);
    setShowQRCode(true); // Show the QR code section when trying to scan again
  };
  return (
    <>
      {showAuthenticationCode ? (
        <Box ta="center" my={20}>
          <Text size="lg" mt={20} fw={500}>
            Can’t scan the QR code?
          </Text>
          <Text size="sm">
            If you can’t scan the QR code with your camera, enter the following code into the
            authentication app to link it to your Source account.
          </Text>
          <Center display="block" px={0} mx={0} mt={20}>
            <Anchor
              left={300}
              onClick={handleShowScanCode}
              display="block"
              underline="never"
              size="sm"
              c={'#0798D0'}>
              Try to scan the QR code again
            </Anchor>
            {isLoading ? (
              <div className=" justify-center flex mt-2">
                <Loader size={20} />
              </div>
            ) : (
              <form onSubmit={form.onSubmit(verifyCode)}>
                <TextInput
                  radius={7}
                  mt={18}
                  w={260}
                  mx="auto"
                  className="font-bold"
                  value={qrCode?.secret}
                />
                <Group justify="center" mt={40}>
                  <Button
                    variant="gradient"
                    gradient={{ from: '#24E3F2', to: '#0798D0', deg: 90 }}
                    w={150}
                    radius={12}
                    mx={10}
                    onClick={handleShowScanCode}
                    disabled={loading}>
                    {loading ? <Loader size={20} /> : 'Continue'}
                  </Button>
                </Group>
              </form>
            )}
          </Center>
        </Box>
      ) : (
        <>
          {showQRCode && (
            <Box ta="center" my={20}>
              <Text ta="left" size="lg" mt={20} fw={500}>
                Setup your authenticator app in 3 steps
              </Text>
              <Text size="sm" ta="left" className="list-decimal">
                <List type="ordered" size="xs" mt={10}>
                  <li className="list-decimal">Install or open the Google Authenticator app</li>
                  <li className="list-decimal">Scan the QR code below with the authenticator</li>
                  <li className="list-decimal">
                    Enter the 6-digit code you see in the authenticator
                  </li>
                </List>
              </Text>
              <Center display="block" px={0} mx={0} mt={20}>
                {isLoading ? (
                  <div className=" justify-center flex mt-2">
                    <Loader size={20} />
                  </div>
                ) : (
                  <img src={qrCode?.qrUrl} alt="QR code" className="mx-auto" width={200} />
                )}

                <Anchor
                  left={300}
                  display="block"
                  underline="never"
                  size="sm"
                  c={'#0798D0'}
                  onClick={handleShowAuthenticationCode}>
                  Can’t scan the QR code?
                </Anchor>
                <form onSubmit={form.onSubmit(verifyCode)}>
                  <TextInput
                    radius={7}
                    mt={18}
                    w={200}
                    mx="auto"
                    placeholder="Enter 6 digit code"
                    {...form.getInputProps('token')}
                  />
                  <Group justify="center" mt={40}>
                    <Button
                      variant="gradient"
                      gradient={{ from: '#24E3F2', to: '#0798D0', deg: 90 }}
                      w={150}
                      radius={12}
                      mx={10}
                      type="submit"
                      disabled={loading}>
                      {loading ? <Loader size={20} /> : 'Continue'}
                    </Button>
                  </Group>
                </form>
              </Center>
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default Authenticator;
