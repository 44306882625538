import { Center, Container, Group, Image, Text } from '@mantine/core';

export const Footer = () => {
  return (
    <Container mt={200} pb={20}>
      <div className="md:flex md:gap-16">
        <div className='flex gap-5'>
          <Image src="/assets/cbn.png" alt="" w={40} />
          <p className="my-auto text-sm">Licensed by the Central Bank of Nigeria </p>
        </div>
        <div className="flex gap-5">
          <Image src="/assets/ndic.png" alt="" w={100} h={40} />
          <p className="text-sm my-1">
            All deposits insured by the <br /> Nigerian Deposit Insurance Corporation.
          </p>
        </div>
      </div>

      <Text size="sm" maw={400} mx="auto" my={20}>
        <span>
          Source Microfinance Bank Limited - &copy;
          {`${new Date().getFullYear()}`}
        </span>
      </Text>
    </Container>
  );
};
