import { Modal } from '@mantine/core';
import Receipt from './Receipt';

type Prop = {
  opened: boolean;
  open: () => void;
  close: () => void;
  approvalData: Transaction[];
};

const Index = ({ opened, close, approvalData }: Prop) => {
  return (
    <>
      <Modal size={513} opened={opened} onClose={close} closeOnClickOutside={false} centered>
        <Receipt approvalData={approvalData} />
      </Modal>
    </>
  );
};

export default Index;
