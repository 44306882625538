import { createContext, useContext, useEffect, useState } from 'react';
import { notifications } from '@mantine/notifications';
import { apiAllAccount, apiSwitchAccount } from '../components/api/ApiServices';
import { useAuth } from './auth.context';

// Create a context to manage account-related data and functions
const AccountContext = createContext<{
  accounts: Account[];
  currentAccount: Account | null;
  updateCurrentAccount: (account: Account) => void;
  switchAccount: (id: string) => void;
  getBusinessAccounts: () => void;
  isLoading: boolean;
}>({
  accounts: [],
  currentAccount: null,
  updateCurrentAccount: () => {},
  switchAccount: () => {},
  getBusinessAccounts: () => {},
  isLoading: false,
});

// Create a hook to access the AccountContext
const useAccount = () => useContext(AccountContext);

// Create a component that provides account-related data and functions
const AccountProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  const [accounts, setAccounts] = useState<Account[]>([]);
  const [currentAccount, setCurrentAccount] = useState<Account | null>(null);

  const getBusinessAccounts = async () => {
    try {
      setIsLoading(true);
      const response = await apiAllAccount();
      setAccounts(response.data.accounts);

      const allAccounts = response.data.accounts;

      // set selected accounts on refresh...
      if (allAccounts.length > 0 && user) {
        const account = allAccounts.filter((account) => account._id === user?.user.account || '');

        if (account.length === 1 && !currentAccount) {
          setCurrentAccount(account[0]);
        } else {
          setCurrentAccount(allAccounts[0]);
        }
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      notifications.show({
        title: 'Something went wrong',
        color: 'red',
        message: 'An error occurred, please try again later.',
      });
    }
  };

  const updateCurrentAccount = (account: Account) => {
    setCurrentAccount(account);
  };

  const switchAccount = async (id: string) => {
    try {
      if (accounts.length > 1) {
        setIsLoading(true);
        await apiSwitchAccount(id);

        const switchTo = accounts.filter((acc) => acc._id === id);

        if (switchTo.length === 1) {
          updateCurrentAccount(switchTo[0]);
        }
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      notifications.show({
        title: 'Something went wrong',
        color: 'red',
        message: 'An error occurred, please try again later.',
      });
    }
  };

  return (
    <AccountContext.Provider
      value={{
        accounts,
        currentAccount,
        updateCurrentAccount,
        switchAccount,
        getBusinessAccounts,
        isLoading,
      }}>
      {children}
    </AccountContext.Provider>
  );
};

export { AccountContext, AccountProvider, useAccount };
