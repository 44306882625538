import { notifications } from '@mantine/notifications';
import { apiUpload } from '../../api/ApiServices';

export const FILE_SIZE_LIMIT = 5 * 1024 * 1024;
export const SUPPORTED_FILE_FORMATS = [
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];
export const MAX_FILE_COUNT = 5;

export const fileUpload = async (
  uploadFiles: File[],
  key: string,
  form: any,
  setIsLoading: (loading: boolean) => void,
  setPayload: (payload: any) => void
) => {
  const files = uploadFiles.length ? uploadFiles : null;

  if (files === null) {
    form.setValues({ [key]: null });
    return;
  }

  // File size and type validation
  for (let i = 0; i < files.length; i++) {
    const file = files[i];
    if (file.size > FILE_SIZE_LIMIT) {
      notifications.show({
        title: 'File size too large',
        color: 'red',
        autoClose: 20000,
        message: `File size of ${file.name} exceeds the limit of 5MB.`,
      });
      return;
    }
    if (!SUPPORTED_FILE_FORMATS.includes(file.type)) {
      notifications.show({
        title: 'Unsupported file format',
        color: 'red',
        autoClose: 20000,
        message: `Unsupported file format for ${file.name}. Only PDF, DOC, DOCX files are allowed.`,
      });
      return;
    }
  }

  // Maximum file count check
  if (files.length > MAX_FILE_COUNT) {
    notifications.show({
      title: 'Maximum number of files exceeded',
      color: 'red',
      autoClose: 20000,
      message: `You can upload a maximum of ${MAX_FILE_COUNT} files.`,
    });
    return;
  }

  const formData = new FormData();
  for (let i = 0; i < files.length; i++) {
    formData.append('files', files[i]);
  }

  try {
    setIsLoading(true);
    const { data } = await apiUpload(formData);
    form.setValues({ [key]: files });
    setPayload((prevPayload: any) => ({ ...prevPayload, [key]: data.links }));
    return data.links;
  } catch (error: any) {
    notifications.show({
      title: 'Something went wrong',
      color: 'red',
      message:
        error?.data?.message || error.message || 'An error occurred, please try again later.',
    });
  } finally {
    setIsLoading(false);
  }
};
