import { Anchor, Box, Group, Table, Text } from '@mantine/core';
import numeral from 'numeral';
import { MdDeleteForever } from 'react-icons/md';
interface AddbeneficiariesTableProp {
  beneficiaries?: Beneficiary[];
  removeBeneficiary?: (index: number) => void;
}

const AddbeneficiariesTable = ({ beneficiaries, removeBeneficiary }: AddbeneficiariesTableProp) => {
  return (
    <>
      <Box bg="#F5F8FF" p={10} my={30} style={{ borderRadius: '8px' }}>
        <Table>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Recipient Name</Table.Th>
              <Table.Th>Amount</Table.Th>
              <Table.Th>Account Number</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody ta="left">
            {beneficiaries &&
              beneficiaries.map((data, idx) => (
                <Table.Tr key={idx}>
                  <Table.Td>{data.accountName}</Table.Td>
                  <Table.Td> ₦{numeral(data?.amount || 0).format('0,0.00')}</Table.Td>
                  <Table.Td>{data.accountNumber}</Table.Td>
                  <Group>
                    {/* <Anchor href="#" className="text-[#0798D0] !mt-2 !text-sm">
                      Edit
                    </Anchor> */}
                    {removeBeneficiary && (
                      <MdDeleteForever
                        onClick={() => removeBeneficiary(idx)}
                        size={18}
                        className=" cursor-pointer mt-2"
                      />
                    )}
                  </Group>
                </Table.Tr>
              ))}
          </Table.Tbody>
        </Table>
      </Box>
    </>
  );
};

export default AddbeneficiariesTable;
