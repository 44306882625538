import { Box, Button, Center, Text } from '@mantine/core';

const SuccessMessage = () => {
  return (
    <>
      <Box ta='center' my={40}>
        <div className='flex justify-center my-5'>
          <img src='/assets/pana.png' alt='' width={150} />
        </div>
        <h4 className='text-[#0798D0] font-medium mt-8 mb-3'>
          Your registration is being reviewed!
        </h4>
        <Text size='sm' px={40}>
          We're currently reviewing the documents you submitted. This process
          typically takes less than an hour, but it may take longer during peak
          times. Once everything is confirmed, you'll receive an email with your
          login credentials.
        </Text>

        <Button
          variant='gradient'
          gradient={{ from: '#24E3F2', to: '#0798D0', deg: 90 }}
          w={180}
          radius={12}
          mx={10}
          mt={40}
          h={37}
          type='submit'
          component='a'
          href='/login'
          //   onClick={handleNext}
        >
          Continue to Log in
        </Button>
      </Box>
    </>
  );
};

export default SuccessMessage;
