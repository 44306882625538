import { Box, Group, Table } from '@mantine/core';
import { MdDeleteForever } from 'react-icons/md';

interface AddDirectorsTableProp {
  signatory?: User[];
  removeDirector?: (index: number) => void;
  roles: any[];
}
const AddDirectorsTable: React.FC<AddDirectorsTableProp> = ({
  signatory,
  removeDirector,
  roles,
}) => {
  return (
    <>
      <Box bg="#F5F8FF" p={10} my={30} style={{ borderRadius: '8px' }}>
        <Table>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Full Name</Table.Th>
              <Table.Th>Email Address</Table.Th>
              <Table.Th>Role</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody ta="left">
            {signatory &&
              signatory.map((data, idx) => (
                <Table.Tr key={idx}>
                  <Table.Td>{`${data.firstName} ${data.lastName}`}</Table.Td>
                  <Table.Td>{data.email}</Table.Td>
                  <Table.Td className="capitalize">
                    {roles.find((role) => role._id === data.role)?.name || 'N/A'}
                  </Table.Td>
                  <Group>
                    {removeDirector && (
                      <MdDeleteForever
                        className="cursor-pointer mt-2"
                        onClick={() => removeDirector(idx)}
                        size={18}
                      />
                    )}
                  </Group>
                </Table.Tr>
              ))}
          </Table.Tbody>
        </Table>
      </Box>
    </>
  );
};

export default AddDirectorsTable;
