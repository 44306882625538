import { AccountProvider } from './account.context';
import { ApprovalProvider } from './approval.context';
import { AuthProvider } from './auth.context';

const ContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}: {
  children: any;
}) => {
  return (
    <>
      <AuthProvider>
        <AccountProvider>
          <ApprovalProvider>{children}</ApprovalProvider>
        </AccountProvider>
      </AuthProvider>
    </>
  );
};

export default ContextProvider;
