import { Box, Group, Table, Text } from '@mantine/core';
import { datas } from '../data';
import { MdDeleteForever } from 'react-icons/md';

interface AddDirectorsTableProp {
  directors?: any[];
  removeDirector?: (index: number) => void;
}
const AddDirectorsTable: React.FC<AddDirectorsTableProp> = ({ directors, removeDirector }) => {
  return (
    <>
      <Box bg="#F5F8FF" p={10} my={30} style={{ borderRadius: '8px' }}>
        <Table>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Full Name</Table.Th>
              <Table.Th>Email Address</Table.Th>
              <Table.Th>Job Title</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody ta="left">
            {directors &&
              directors.map((data, idx) => (
                <Table.Tr key={data.idx}>
                  <Table.Td>{`${data.firstName} ${data.lastName}`}</Table.Td>
                  <Table.Td>{data.email}</Table.Td>
                  <Table.Td>{data.jobTitle}</Table.Td>
                  <Group>
                    {removeDirector && (
                      <MdDeleteForever onClick={() => removeDirector(idx)} size={18} className='mt-2' />
                    )}
                  </Group>
                </Table.Tr>
              ))}
          </Table.Tbody>
        </Table>
      </Box>
    </>
  );
};

export default AddDirectorsTable;
