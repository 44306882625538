import React from 'react';
import Header from './Header';
import SideBar from './SideBar';
import style from '../../styles/style.module.css';
import InactivityModal from './InactivityModal';
import ProtectedRoutes from '../../../common/ProtectedRoutes';

export default function DashLayout({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) {
  return (
    <ProtectedRoutes>
      <div className={style.grid_container}>
        <InactivityModal />
        <Header />
        <SideBar />
        {children}
      </div>
    </ProtectedRoutes>
  );
}
