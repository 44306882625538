import { HiOutlineChatBubbleLeftRight } from 'react-icons/hi2';
import { FaChevronRight } from 'react-icons/fa6';
import { SetStateAction, useEffect, useState } from 'react';

const faqs = [
  {
    id: 1,
    question: 'How do I reset my password?',
    answer:
      'Click on "forgot password" on the login and provide your email address. A password reset link will be sent to your mail',
  },
  {
    id: 2,
    question: 'How do I make bulk transfer with sheet?',
    answer:
      'Click on "Bulk Transfer" on the Fund Transfer page of your dashboard. Select "CSV" tab. Select yor preferred file and upload. Click next and review transaction details before confirmation.',
  },
];

interface AccordionProps {
  id: number;
  question: string;
  answer: string;
  active: number | null;
  setIsActive: React.Dispatch<SetStateAction<null | number>>;
}

function AccordionLabel({ active, id, question, answer, setIsActive }: AccordionProps) {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (id !== active) {
      setIsOpen(false);
    }
  }, [active]);

  return (
    <div
      onClick={() => {
        setIsActive(id);
        setIsOpen(!isOpen);
      }}
      className="w-1/2">
      <h1 className="flex justify-start items-center gap-2 font-bold hover:text-blue-600 duration-100 ease-in">
        <div className="flex justify-start items-center gap-3 font-semibold">
          <span>
            <HiOutlineChatBubbleLeftRight />
          </span>
          <span>{question}</span>
        </div>
        <FaChevronRight className={`${isOpen ? 'rotate-90' : ''} duration-200 ease-in-out`} />
      </h1>
      <p className={`${isOpen ? 'h-full' : 'h-0 hidden'} text-xs mt-3 font-medium duration-300`}>
        Answer: {answer}
      </p>

      <div className="h-[0.1rem] bg-black w-1/3 my-2"></div>
    </div>
  );
}

function FAQs() {
  const [isActive, setIsActive] = useState<number | null>(null);
  return (
    <div className="cursor-pointer">
      {faqs.map(({ answer, id, question }) => {
        return (
          <div key={id} className="mb-4">
            <AccordionLabel
              active={isActive}
              answer={answer}
              id={id}
              question={question}
              setIsActive={setIsActive}
            />
          </div>
        );
      })}
    </div>
  );
}

export default FAQs;
