import { Anchor, Box, Button, Grid, Group, Table } from '@mantine/core';
import AddbeneficiariesTable from '../AddBeneficiariesTable';
import numeral from 'numeral';
import { MdDeleteForever } from 'react-icons/md';
import { getCBNTransferFee } from '../modal/Step2';

type Step2Props = {
  onNext: () => void;
  onPrev?: () => void;
  beneficiaries: Beneficiary[];
  removeBeneficiary: (index: number) => void;
};

const Step2 = ({ onNext, onPrev, beneficiaries, removeBeneficiary }: Step2Props) => {
  // Calculate total amount to send
  const calculateTotalAmount = (beneficiaries: Beneficiary[]) => {
    return beneficiaries.reduce((total, beneficiary) => {
      const amount = parseFloat(beneficiary.amount || '0');
      return total + amount;
    }, 0);
  };

  // Calculate total transaction fee
  const calculateTotalFee = (beneficiaries: Beneficiary[]) => {
    return beneficiaries.reduce((totalFee, beneficiary) => {
      const amount = parseFloat(beneficiary.amount || '0');
      const fee = getCBNTransferFee(amount, beneficiary.bankCode);
      return totalFee + fee;
    }, 0);
  };

  const totalAmount = calculateTotalAmount(beneficiaries);
  const totalFee = calculateTotalFee(beneficiaries);
  const grandTotal = totalAmount + totalFee;

  const handleNext = () => {
    onNext();
  };

  return (
    <section className="p-2">
      <div className="flex justify-between mt-4">
        <h1 className="font-semibold">Transfer Details</h1>
        {/* <Anchor href="#" className="!underline !text-black">
          Edit
        </Anchor> */}
      </div>
      <div className="flex justify-between mt-5">
        <p className="text-sm ">Amount to send</p>
        <h1 className=" font-medium">₦{numeral(totalAmount).format('0,0.00')}</h1>
      </div>
      <div className="flex justify-between mt-2">
        <p className="text-sm ">Transaction fee</p>
        <h1 className="text-sm">₦{numeral(totalFee).format('0,0.00')}</h1>
      </div>
      <div className="flex justify-between mt-2">
        <p className="text-sm ">Total</p>
        <h1 className="text-sm">₦{numeral(grandTotal).format('0,0.00')}</h1>
      </div>
      <hr className="font-100 my-6" />
      <div className="flex justify-between">
        <h1 className="font-semibold">Recipient Details</h1>
        {/* <Anchor href="#" className=" !text-black !underline">
          Change
        </Anchor> */}
      </div>

      <AddbeneficiariesTable beneficiaries={beneficiaries} removeBeneficiary={removeBeneficiary} />

      <hr className="font-bold my-6" />
      <div>
        <h1 className="font-semibold">Schedule Details</h1>
      </div>
      <div className="flex justify-between mt-5">
        <p className="text-sm ">Send date</p>
        <p className="text-sm">{new Date().toLocaleDateString()}</p>
      </div>
      <div className="flex justify-between mt-3">
        <p className="text-sm ">Should arrive</p>
        <p className="text-sm">Instantly</p>
      </div>
      <Grid className="mt-12 mb-10">
        <Grid.Col span={6}>
          <Button
            variant="outline"
            fullWidth
            radius={12}
            type="button"
            component="a"
            onClick={onPrev}>
            Back
          </Button>
        </Grid.Col>
        <Grid.Col span={6}>
          <Button
            variant="gradient"
            gradient={{ from: '#24E3F2', to: '#0798D0', deg: 90 }}
            fullWidth
            radius={12}
            type="submit"
            onClick={handleNext}
            // disabled={isLoading}
          >
            {/* {isLoading ? <Loader size={20} /> : 'Next'} */}
            Send for Approval
          </Button>
        </Grid.Col>
      </Grid>
    </section>
  );
};

export default Step2;
