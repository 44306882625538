import { Button, Grid, Loader, PinInput } from '@mantine/core';
import { yupResolver } from 'mantine-form-yup-resolver';
import { useEffect, useState } from 'react';
import { useForm } from '@mantine/form';
import * as yup from 'yup';

type Prop = {
  onPrev: () => void;
  approvalData: Transaction[];
  onSend: (pin: string) => void;
  isApproval: boolean;
  isLoading: boolean;
};

const Step2 = ({ onPrev, onSend, approvalData, isApproval, isLoading }: Prop) => {
  const schema = yup.object().shape({
    pin: yup.number().required('Pin is required'),
  });

  const form = useForm({
    initialValues: {
      pin: '',
    },

    validate: yupResolver(schema),
  });
  useEffect(() => {
    console.log('Approval Data:', approvalData);
  }, [approvalData]);

  const handleSubmit = (values: { pin: string }) => {
    onSend(values.pin);
  };

  return (
    <>
      <section>
        <div className="text-center w-2/3 mx-auto">
          <h1>
            Enter your 2 factor authentication code to {isApproval ? 'approve' : 'reject'} this
            transaction
          </h1>
          <form onSubmit={form.onSubmit(handleSubmit)}>
            <PinInput
              length={6}
              className="w-3/4 mx-auto text-center my-5"
              type="number"
              {...form.getInputProps('pin')}
            />

            <Grid className="mt-12 mb-10">
              <Grid.Col span={6}>
                <Button
                  variant="outline"
                  fullWidth
                  radius={12}
                  type="button"
                  component="a"
                  onClick={onPrev}>
                  Back
                </Button>
              </Grid.Col>
              <Grid.Col span={6}>
                <Button
                  variant="gradient"
                  gradient={{ from: '#24E3F2', to: '#0798D0', deg: 90 }}
                  fullWidth
                  radius={12}
                  type="submit"
                  // onClick={onNext}
                  disabled={isLoading}>
                  {isLoading ? <Loader size={20} /> : 'Send'}
                </Button>
              </Grid.Col>
            </Grid>
          </form>
        </div>
      </section>
    </>
  );
};

export default Step2;
