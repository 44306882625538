import { Button, Grid, Group, Loader, Text, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { yupResolver } from 'mantine-form-yup-resolver';
import { useState } from 'react';
import { MdOutlineArrowBack } from 'react-icons/md';
import * as yup from 'yup';
import { apiToken, apiresendToken } from '../../api/ApiServices';
import { notifications } from '@mantine/notifications';

const EmailCode = ({ onPrevStep, onNextStep, formData }: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [resendCode, setresendCode] = useState<string | null>(null);

  const schema = yup.object().shape({
    code: yup.string().required('Code is required ').typeError('Enter Code'),
  });

  const form = useForm({
    initialValues: {
      code: '',
    },
    validate: yupResolver(schema),
  });

  // apiToken;
  const token = async (params: { code: any }) => {
    try {
      setIsLoading(true);
      const payload = {
        code: params.code,
      };
      const id = localStorage.getItem('registeredId') || '';
      const resp = await apiToken(payload, id);
      console.log('identity', id);
      setIsLoading(false);
      onNextStep();
    } catch (error: any) {
      setError(
        notifications.show({
          title: 'Something went wrong',
          color: 'red',
          message:
            error?.data?.message || error.message || 'An error occurred, please try again later.',
        })
      );
      console.log('Error occurred while verifying code:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // resend token
  const resendToken = async () => {
    setIsLoading(true);
    try {
      const id = localStorage.getItem('registeredId') || '';
      const resp = await apiresendToken(id);
      setIsLoading(false);
      console.log('identity', id);
      setresendCode(
        notifications.show({
          title: 'Resend Code',
          color: 'green',
          message: 'A new code has been sent to your email',
        })
      );
    } catch (error: any) {
      setIsLoading(false);
      setError(
        notifications.show({
          title: 'Something went wrong',
          color: 'red',
          message:
            error?.data?.message || error.message || 'An error occurred, please try again later.',
        })
      );
      console.log('Error occurred while verifying code:', error);
    }
  };
  return (
    <>
      <form onSubmit={form.onSubmit(token)}>
        <Text size="xl" mt={30} fw={500}>
          We’ve sent you an email
        </Text>
        <Grid mt={10} mb={60} grow>
          <Grid.Col span={12}>
            <label htmlFor="email" className="text-sm">
              Please enter the code we've sent to
              <span className="font-medium"> {formData?.email || ' '}</span>
            </label>
            <TextInput withAsterisk radius={5} placeholder="" {...form.getInputProps('code')} />
            <p className="mt-6">
              The code hasn't arrived?
              <span className="text-[#0798D0] font-medium">
                {' '}
                <button type="button" onClick={resendToken} disabled={isLoading}>
                  {' '}
                  Resend code
                </button>
              </span>
            </p>
          </Grid.Col>
        </Grid>
        <Group justify="center" mt="xl">
          <Button
            bg="transparent"
            color="#0798D0"
            w={180}
            radius={12}
            mx={10}
            h={37}
            leftSection={<MdOutlineArrowBack size={16} />}
            variant="light"
            type="button"
            component="a"
            onClick={onPrevStep}>
            Back
          </Button>
          <Button
            variant="gradient"
            gradient={{ from: '#24E3F2', to: '#0798D0', deg: 90 }}
            w={180}
            radius={12}
            mx={10}
            type="submit"
            disabled={isLoading}>
            {isLoading ? <Loader size={20} /> : 'Save and Continue'}
          </Button>
        </Group>
      </form>
    </>
  );
};

export default EmailCode;
