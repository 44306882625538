import {
  Card,
  Container,
  BackgroundImage,
  Text,
  Flex,
  Stepper,
} from '@mantine/core';

import 'react-form-wizard-component/dist/style.css';
import { SetStateAction, useEffect, useState } from 'react';
import SuccessMessage from '../common/SuccessMessage';
import NewPassWord from './component/NewPassword';
import Authenticator from './component/Authenticator';

const ResetPassword = () => {
  const [active, setActive] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [formData, setFormData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const [step, setStep] = useState(0);

  const steps = [
    'NewPassword',
    'Authenticator',
  ];

  const nextStep = () => setActive((current) => Math.min(current + 1, steps.length - 1));
  const prevStep = () => setActive((current) => Math.max(current - 1, 0));

  const handleNextStep = () => {
    nextStep();
    setStep((prevStep) => prevStep + 1);
  };

  const updateFormData = (newData: SetStateAction<{}>) => {
    setFormData((prevFormData) => ({ ...prevFormData, ...newData }));
  };

  const handleFinishClick = () => {
    if (active === steps.length - 2) {
      setShowSuccess(true);
      console.log(formData);
    } else {
      nextStep();
    }
  };

  useEffect(() => {
    const storedStep = parseInt(localStorage.getItem('passStep') || '');
    console.log('Retrieved passStep from localStorage:', storedStep);
    setActive(!isNaN(storedStep) ? storedStep : 0);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    console.log('Setting passStep in localStorage:', active);
    localStorage.setItem('passStep', String(active));
  }, [active]);

  if (isLoading) {
    return <div>Loading...</div>;
  }
  return (
    <>
      <Container px={0}>
        <Card shadow="md" padding="xl" radius="md" maw={550} mx="auto" >
          <Card.Section mx={-60}>
            <BackgroundImage src="/assets/form-bg.png" h={90}>
              <Flex
                direction={{ base: 'column', sm: 'row' }}
                gap={40}
                justify={{ sm: 'center' }}
                pt={5}>
                <Text size="lg" c="white" mt={10}>
                  Let’s get you started with a
                  <span className="block font-[700]">Source Business Account</span>
                </Text>
              </Flex>
            </BackgroundImage>
          </Card.Section>

          {!showSuccess && (
            <>
              <Stepper active={active} size="xs" mt={35} mb={40} onStepClick={setActive}>
                <Stepper.Step disabled>
                  <NewPassWord onNextStep={handleNextStep} />
                </Stepper.Step>
                <Stepper.Step disabled>
                  <Authenticator />
                </Stepper.Step>
              </Stepper>
            </>
          )}

          {showSuccess && (
            <>
              <SuccessMessage />
            </>
          )}
        </Card>
      </Container>
    </>
  );
};
export default ResetPassword;
