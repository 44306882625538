import { createContext, useContext, useEffect, useState } from 'react';
import { apiFetchCurrentUser } from '../components/api/ApiServices';
import { notifications } from '@mantine/notifications';

// Create a context to manage authentication-related data and functions
const AuthContext = createContext<{
  user: IUserResp | null;
  loading: boolean;
  updateUser: (user: IUserResp | null) => void;
  getUser: () => Promise<void>;
}>({
  user: null,
  loading: true,
  updateUser: () => {},
  getUser: async () => {},
});

// Create a hook to access the AuthContext
const useAuth = () => useContext(AuthContext);

// Create a component that provides authentication-related data and functions
const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<IUserResp | null>(null);

  const getUser = async () => {
    if (user && user.user._id) setLoading(false);

    try {
      const resp = await apiFetchCurrentUser();
      setUser(resp.data);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      notifications.show({
        title: 'Invalid Credentials',
        color: 'red',
        message: 'Something went wrong',
      });
      setUser(null);
    }
  };

  const updateUser = (user: IUserResp | null) => {
    setUser(user);
  };

  return (
    <AuthContext.Provider value={{ user, updateUser, getUser, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider, useAuth };
