import {
  Grid,
  TextInput,
  Textarea,
  Radio,
  Group,
  Text,
  Select,
  Button,
  Loader,
} from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import dayjs from 'dayjs';
import { yupResolver } from 'mantine-form-yup-resolver';
import React, { useRef, useState } from 'react';
import { MdOutlineArrowBack } from 'react-icons/md';
import * as yup from 'yup';
import { apiRegister } from '../../../api/ApiServices';
import ReCAPTCHA from 'react-google-recaptcha';
import Recaptcha, { verifyRecaptcha } from '../../common/Recaptcha';

const NonRegisterIntro = ({ onNextStep, updateFormData }: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const recaptcha = useRef<ReCAPTCHA | null>(null);

  const nonRegisteredStepOne = async (params: any) => {
     let reCaptchaToken = '';

     try {
       reCaptchaToken = verifyRecaptcha(recaptcha);
     } catch (error: any) {
       notifications.show({
         title: 'reCAPTCHA failed',
         message: error?.message || JSON.stringify(error) || 'Please try the reCAPTCHA again',
         color: 'red',
       });
       return;
     }

     try {
       setIsLoading(true);
       updateFormData(params);
       let type = 'non_registered_account';

       const payload = {
         type,
         reCaptchaToken,
         meta: {},
       } as any;

       Object.entries(params).forEach(([key, value]) => {
         const keySplit = key.split('meta_');
         const payloadKey = keySplit[1] || keySplit[0];
         if (payloadKey === 'dateOfIncorporation') {
           value = dayjs(value as string).format('YYYY-MM-DD');
         }
         if (keySplit[1]) {
           payload.meta[payloadKey] = value;
         } else {
           payload[payloadKey] = value;
         }
       });

       const resp = await apiRegister(payload);
       localStorage.setItem('registeredId', resp.data._id);

       console.log(params, payload);
       onNextStep();
       localStorage.setItem(`nonRegisteredStep${step}`, JSON.stringify(params));
       setIsLoading(false);
     } catch (error: any) {
       setIsLoading(false);
       setError(
         notifications.show({
           title: 'Something went wrong',
           color: 'red',
           message:
             error?.data?.message || error.message || 'An error occurred, please try again later.',
         })
       );
       console.log(error);
     } finally {
       setIsLoading(false);
     }
  };

  React.useEffect(() => {
    console.log('onload');
    const saved = localStorage.getItem(`nonRegisteredStep${step}`);
    // if (saved) {
    //   const jsonSaved = JSON.parse(saved);
    //   console.log(jsonSaved);
    //   form.setValues({
    //     ...jsonSaved,
    //     meta_dateOfIncorporation: new Date(jsonSaved.meta_dateOfIncorporation),
    //   });
    // } else {
    // }
  }, []);

  const schema = yup.object().shape({
    name: yup.string().required('Association Name is required'),
    nature: yup.string().required('Type of business is required'),
    website: yup.string().url('Invalid website URL').optional(),
    phoneNumber: yup.string().required('Association phone number is required'),
    email: yup
      .string()
      .required('Association email address is required')
      .email('Invalid email format'),
    address: yup.string().required('Association Address is required'),
    meta_city: yup.string().required('City is required'),

    meta_country: yup.string().required('Country is required'),
  });

  const form = useForm({
    initialValues: {
      name: '',
      nature: '',
      website: '',
      phoneNumber: '',
      email: '',
      address: '',
      meta_city: '',
      meta_country: '',
    },

    validate: yupResolver(schema),
  });

  const [active, setActive] = useState(0);
  const [step, setStep] = useState(0);
  const prevStep = () => setActive((current) => Math.max(current - 1, 0));

  const handleBackClick = () => {
    if (active === 0) {
      window.history.back();
    } else {
      prevStep();
    }
  };
  return (
    <>
      <Text size="lg" mt={20} fw={500} ta="left">
        Tell us about your business
      </Text>
      <Text size="sm" ta="left">
        Make sure this is the same information as on your Certificate of Incorporation and
        Registration so we can verify it later on.
      </Text>
      <form onSubmit={form.onSubmit(nonRegisteredStepOne)}>
        <Grid mt={20} mb={60} grow>
          <Grid.Col span={6}>
            <TextInput
              ta="left"
              label="Association Name"
              withAsterisk
              radius={5}
              px={0}
              my={10}
              placeholder=""
              {...form.getInputProps('name')}
            />
          </Grid.Col>

          <Grid.Col span={12}>
            <TextInput
              ta="left"
              label="Type/Nature of Business"
              withAsterisk
              radius={5}
              px={0}
              my={10}
              placeholder=" "
              {...form.getInputProps('nature')}
            />
          </Grid.Col>

          <Grid.Col span={6}>
            <TextInput
              ta="left"
              label="Association website (optional)"
              radius={5}
              px={0}
              my={10}
              placeholder=""
              {...form.getInputProps('website')}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              ta="left"
              label="Association phone number"
              withAsterisk
              radius={5}
              px={0}
              my={10}
              placeholder=""
              {...form.getInputProps('phoneNumber')}
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <TextInput
              ta="left"
              label="Association email address"
              withAsterisk
              radius={5}
              px={0}
              my={10}
              placeholder=" "
              {...form.getInputProps('email')}
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <Textarea
              ta="left"
              label="Association Address"
              withAsterisk
              radius={5}
              px={0}
              my={10}
              placeholder=""
              {...form.getInputProps('address')}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              ta="left"
              label="City"
              withAsterisk
              radius={5}
              px={0}
              my={10}
              placeholder=" "
              {...form.getInputProps('meta_city')}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              ta="left"
              label="Country"
              withAsterisk
              radius={5}
              px={0}
              my={10}
              placeholder=" "
              {...form.getInputProps('meta_country')}
            />
          </Grid.Col>
        </Grid>

        <Recaptcha recaptcha={recaptcha} />

        <Group justify="center" mt="xl">
          <Button
            bg="transparent"
            color="#0798D0"
            w={180}
            radius={12}
            mx={10}
            h={37}
            leftSection={<MdOutlineArrowBack size={16} />}
            variant="light"
            type="button"
            component="a"
            onClick={handleBackClick}>
            Back
          </Button>
          <Button
            variant="gradient"
            gradient={{ from: '#24E3F2', to: '#0798D0', deg: 90 }}
            w={180}
            radius={12}
            mx={10}
            type="submit"
            disabled={isLoading}>
            {isLoading ? <Loader size={20} /> : 'Save and Continue'}
          </Button>
        </Group>
      </form>
    </>
  );
};

export default NonRegisterIntro;
