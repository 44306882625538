import { Anchor, Button, Grid } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import numeral from 'numeral';
import { MAX_TRANSFER_AMOUNT } from '../../constant';

type Step2Props = {
  onPrev: () => void;
  onNext: () => void;
  formData: Beneficiary;
};

export const getCBNTransferFee = (amount: any, bankCode: string) => {
  let fee = 0;
  if (bankCode === '090641') return 0;

  amount = parseInt(amount, 10);

  if (amount && Number.isFinite(amount) && amount > 0) {
    if (amount > 50000) {
      fee = 50;
    } else if (amount > 5000 && amount <= 50000) {
      fee = 26;
    } else if (amount <= 5000) {
      fee = 10;
    }
  } else {
    throw 'Invalid amount passed';
  }

  if (!fee) throw 'Request could not be completed at the moment';

  if (bankCode === '090641') {
    fee = 0;
  }

  return fee * 1.075;
};

const Step2 = ({ onPrev, onNext, formData }: Step2Props) => {
  const amount = Number(formData?.amount || 0);
  const fee = getCBNTransferFee(amount, formData.bankCode);
  const total = amount + fee;

  const handleNext = () => {
    if (total > MAX_TRANSFER_AMOUNT) {
      notifications.show({
        title: 'Transfer Limit',
        color: 'orange',
        message: `Exceeded transfer amount limit of ₦${numeral(MAX_TRANSFER_AMOUNT).format(
          '0,0.00'
        )}. Please reduce the amount and try again`,
      });
      return;
    }
    onNext();
  };

  return (
    <section className="p-2">
      <div className="flex justify-between mt-4">
        <h1 className="font-semibold">Transfer Details</h1>
        <Anchor href="#" onClick={onPrev} className="text-blue-400 !underline">
          Edit
        </Anchor>
      </div>
      <div className="flex justify-between mt-5">
        <p className="text-sm">Amount to send</p>
        <h1 className="font-medium">₦{numeral(amount).format('0,0.00')}</h1>
      </div>
      <div className="flex justify-between mt-2">
        <p className="text-sm">Transaction fee</p>
        <h1 className="text-sm">₦{numeral(fee).format('0,0.00')}</h1>
      </div>
      <div className="flex justify-between mt-2">
        <p className="text-sm">Total</p>
        <h1 className="text-sm">₦{numeral(total).format('0,0.00')}</h1>
      </div>
      <hr className="font-100 my-6" />
      <div className="flex justify-between">
        <h1 className="font-semibold">Recipient Details</h1>
        <Anchor href="#" onClick={onPrev} className="text-blue-400 !underline">
          Change
        </Anchor>
      </div>
      <div className="flex justify-between mt-5">
        <p className="text-sm">Bank</p>
        <p className="text-sm">{formData?.bankName}</p>
      </div>
      <div className="flex justify-between mt-3">
        <p className="text-sm">Account number</p>
        <p className="text-sm">{formData?.accountNumber}</p>
      </div>
      <div className="flex justify-between mt-3">
        <p className="text-sm">Account name</p>
        <p className="text-sm">{formData?.accountName}</p>
      </div>
      <hr className="font-bold my-6" />
      <Grid className="mt-12 mb-10">
        <Grid.Col span={6}>
          <Button
            variant="outline"
            fullWidth
            radius={12}
            type="button"
            component="a"
            onClick={onPrev}>
            Back
          </Button>
        </Grid.Col>
        <Grid.Col span={6}>
          <Button
            variant="gradient"
            gradient={{ from: '#24E3F2', to: '#0798D0', deg: 90 }}
            fullWidth
            radius={12}
            type="submit"
            onClick={handleNext}>
            Send for Approval
          </Button>
        </Grid.Col>
      </Grid>
    </section>
  );
};

export default Step2;
