import { Grid, TextInput, Textarea, Group, Text, Select, Box, Button, Loader, LoadingOverlay } from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { yupResolver } from 'mantine-form-yup-resolver';
import * as yup from 'yup';
import AddDirectorsTable from './AddDirectorsTable';
import { FaPlus } from 'react-icons/fa';
import { MdOutlineArrowBack } from 'react-icons/md';
import { useState } from 'react';
import dayjs from 'dayjs';
import FileUpload from '../../common/FileUpload';
import React from 'react';
import { notifications } from '@mantine/notifications';
import { apiAddDirectors, apiUpload } from '../../../api/ApiServices';

const AddDirectors = ({ onPrevStep, onNextStep }: any) => {
  const [step, setStep] = useState(3);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [submit, setSubmit] = useState(false);

  const schema = yup.object().shape({
    firstName: yup.string().required('First Name is required'),
    meta_middleName: yup.string().required('Middle Name is required'),
    lastName: yup.string().required('Last Name is required'),
    meta_motherMaidenName: yup.string().required('Maiden Name is required'),
    bvn: yup.number().required('BVN is required').typeError('Invalid BVN'),
    meta_nin: yup.number().required('NIN is required').typeError('Invalid NIN'),
    gender: yup.string().required('Gender is required'),
    dob: yup.date().required('Date of Birth is required').typeError('Invalid date'),
    nationality: yup.string().required('Nationality is required'),
    identification_type: yup.string().required('ID Type is required'),
    identification_number: yup
      .string()
      .required('ID Number is required')
      .typeError('Invalid ID number'),
    identification_placeOfIssue: yup.string().required('Place Issued is required'),
    identification_issueDate: yup
      .date()
      .required('Issue Date is required')
      .typeError('Invalid Issue date'),
    identification_expiryDate: yup
      .date()
      .required('Expiry Date is required')
      .typeError('Invalid Expiry date'),
    residentPermit_number: yup.string().nullable().optional(),
    residentPermit_issueDate: yup
      .date()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .nullable()
      .typeError('Invalid Date'),
    residentPermit_expiryDate: yup
      .date()
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .typeError('Invalid Date'),
    jobTitle: yup.string().required('Job Title is required'),
    occupation: yup.string().required('Occupation is required'),
    address_location: yup.string().required('Home Address is required'),
    address_city: yup.string().required('City is required'),
    address_country: yup.string().required('Country is required'),
    email: yup.string().required('Email Address is required').email('Invalid email address'),
    phoneNumber: yup.string().required('Phone Number is required'),
    passportPhoto: yup.mixed().required('Passport is required') as yup.MixedSchema<File | null>,
    identification_doc: yup
      .mixed()
      .required('ID shareholder is required') as yup.MixedSchema<File | null>,
    residentPermit_doc: yup
      .mixed()
      .optional().nullable() as yup.MixedSchema<File | null>,
    utilityBill: yup.mixed().required('Utility is required') as yup.MixedSchema<File | null>,
  });

  const form = useForm({
    initialValues: {
      firstName: '',
      meta_middleName: '',
      lastName: '',
      meta_motherMaidenName: '',
      bvn: '',
      meta_nin: '',
      gender: '',
      dob: '',
      nationality: '',
      identification_type: '',
      identification_number: '',
      identification_placeOfIssue: '',
      identification_issueDate: '',
      identification_expiryDate: '',
      residentPermit_number: '',
      residentPermit_issueDate: '',
      residentPermit_expiryDate: '',
      jobTitle: '',
      occupation: '',
      address_location: '',
      address_city: '',
      address_country: '',
      email: '',
      phoneNumber: '',
      passportPhoto: null,
      identification_doc: null,
      residentPermit_doc: null,
      utilityBill: null,
    },

    validate: yupResolver(schema),
  });

  // retrieve data
  React.useEffect(() => {
    console.log('onload');
    const director = localStorage.getItem(`addDirectors${step}`);
    if (director) {
      const directorSaved = JSON.parse(director);
      console.log(directorSaved);
      setDirectors([directorSaved]);
    } else {
    }
  }, []);

  const [files, setFiles] = useState({});
  const FILE_SIZE_LIMIT = 5 * 1024 * 1024;
  const SUPPORTED_FILE_FORMATS = [
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ];
  const MAX_FILE_COUNT = 5;
  // Function to handle file upload
  const fileUpload = async (uploadFiles: File[], key: any) => {
    const filesToUpload = uploadFiles.length ? uploadFiles : null;

    if (filesToUpload === null) {
      form.setValues({ [key]: null });
      return;
    }
    // File size and type validation
    for (let i = 0; i < filesToUpload.length; i++) {
      const file = filesToUpload[i];
      if (file.size > FILE_SIZE_LIMIT) {
        setError(
          notifications.show({
            title: 'File size too large',
            color: 'red',
            autoClose: 20000,
            message: `File size of ${file.name} exceeds the limit of 5MB.`,
          })
        );
        return;
      }
      // File type validation
      if (!SUPPORTED_FILE_FORMATS.includes(file.type)) {
        setError(
          notifications.show({
            title: 'Unsupported file format',
            color: 'red',
            autoClose: 20000,
            message: `Unsupported file format for ${file.name}. Only PDF, DOC, DOCX files are allowed.`,
          })
        );
        return;
      }
    }

    // Maximum file count check
    if (filesToUpload.length > MAX_FILE_COUNT) {
      setError(
        notifications.show({
          title: 'Maximum number of files exceeded',
          color: 'red',
          autoClose: 20000,
          message: `You can upload a maximum of ${MAX_FILE_COUNT} files.`,
        })
      );
      return;
    }

    const formData = new FormData();

    //  console.log(files, key);

    for (let i = 0; i < filesToUpload.length; i++) {
      const file = filesToUpload[i];
      formData.append('files', file);
    }

    try {
      setIsLoading(true);

      const { data } = await apiUpload(formData);
      const links = data.links;
      form.setValues({ [key]: filesToUpload });
      console.log(key, { ...files, [key]: data.links });
      setFiles({ ...files, [key]: data.links });
      return links;
    } catch (error: any) {
      setError(
        notifications.show({
          title: 'Something went wrong',
          color: 'red',
          message:
            error?.data?.message || error.message || 'An error occurred, please try again later.',
        })
      );
    } finally {
      setIsLoading(false);
    }
  };
  const modObject = (obj: Record<string, any>) => {
    let modifiedObject: any = {};
    Object.entries(obj).map(([key, value]) => {
      const keySplit = key.split('_');
      const payloadKey = keySplit[1] || keySplit[0];
     if (
       value &&
       (payloadKey === 'dob' || payloadKey === 'expiryDate' || payloadKey === 'issueDate')
     ) {
       // if key is a date value, do the check here
       value = dayjs(value as string).format('YYYY-MM-DD');
     }
      if (keySplit[1]) {
        // init the nesedt object with an object to avoid error
        if (!modifiedObject[keySplit[0]]) modifiedObject[keySplit[0]] = {};

        modifiedObject[keySplit[0]][payloadKey] = value;
      } else {
        modifiedObject[payloadKey] = value;
      }
    });

    return modifiedObject;
  };

  const [directors, setDirectors] = useState<any[]>([]);
  // remove dircetories
  const removeDirector = async (index: number) => {
    try {
      const directersCopy = [...directors];

      directersCopy.splice(index, 1);

      setDirectors(directersCopy);
      console.log(index, 'index to remove');
    } catch (error: any) {
      setError(
        notifications.show({
          title: 'Something went wrong',
          color: 'red',
          message:
            error?.data?.message || error.message || 'An error occurred, please try again later.',
        })
      );
      console.log(error);
    }
  };
  // add directories
  const addDirector = async (data: any) => {
    try {
      console.log(form, 'form');
      console.log(data);
      if (form) {
        // replace file files in data into a link
        setDirectors([...directors, { ...data, ...files }]);
        console.log([...directors, { ...data, ...files }], 'form');

        // reset form and file uploads
        form.reset();
        setFiles({});
      }
    } catch (error: any) {
      setError(
        notifications.show({
          title: 'Something went wrong',
          color: 'red',
          message:
            error?.data?.message || error.message || 'An error occurred, please try again later.',
        })
      );
      console.log(error);
    }
  };
  // handle final submit
  const handleSaveContinue = () => {
    console.log('handleSaveContinue');

    const modDirectors = directors.map((director) => {
      return modObject(director);
    });
    console.log(modDirectors);
    console.log('modDirectors:', modDirectors);
    localStorage.setItem(`addDirectors${step}`, JSON.stringify([...directors]));
    // call API
    const registerDirector = async () => {
      try {
        setSubmit(true);
        const id = localStorage.getItem('registeredId') || '';
        const resp = await apiAddDirectors(id, modDirectors);

        console.log('identity', id, modDirectors);
        onNextStep();
      } catch (error: any) {
        setError(
          notifications.show({
            title: 'Something went wrong',
            color: 'red',
            message:
              error?.data?.message || error.message || 'An error occurred, please try again later.',
          })
        );
        console.log('Error occurred while adding directors:', error);
      } finally {
        setSubmit(false);
      }
    };
    registerDirector();
  };

  return (
    <>
     <Box pos="relative">
        <LoadingOverlay
          zIndex={1000}
          visible={isLoading}
          overlayProps={{ radius: 'sm', blur: 1 }}
        />

      <Text size="lg" mt={20} fw={500} ta="left">
        Add Directors
      </Text>
      <Text size="sm" ta="left">
        Make sure this is the same information as on their passport or ID, so we can verify it
        later. This should be filled by directors with 5% share holding or more.
      </Text>
      <form onSubmit={form.onSubmit(addDirector)}>
        <Grid mt={20} mb={50} grow>
          <Grid.Col span={6}>
            <TextInput
              ta="left"
              label="First Name"
              withAsterisk
              radius={5}
              px={0}
              my={10}
              placeholder=""
              {...form.getInputProps('firstName')}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              ta="left"
              label="Middle Name"
              withAsterisk
              radius={5}
              px={0}
              my={10}
              placeholder=""
              {...form.getInputProps('meta_middleName')}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              ta="left"
              label="Last Name"
              withAsterisk
              radius={5}
              px={0}
              my={10}
              placeholder=""
              {...form.getInputProps('lastName')}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              ta="left"
              label="Mother’s maiden name"
              withAsterisk
              radius={5}
              px={0}
              my={10}
              placeholder=""
              {...form.getInputProps('meta_motherMaidenName')}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              ta="left"
              label="BVN "
              withAsterisk
              radius={5}
              px={0}
              my={10}
              placeholder=""
              type="number"
              {...form.getInputProps('bvn')}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              ta="left"
              label="NIN"
              withAsterisk
              radius={5}
              px={0}
              my={10}
              placeholder=""
              type="number"
              {...form.getInputProps('meta_nin')}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <DateInput
              ta="left"
              valueFormat="YYYY-MMM-DD"
              label="Date of Birth"
              clearable
              my={10}
              withAsterisk
              placeholder="DD-MM-YYYY"
              {...form.getInputProps('dob')}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <Select
              ta="left"
              my={10}
              label="Gender"
              withAsterisk
              placeholder="Pick value"
              data={['Female', 'Male']}
              {...form.getInputProps('gender')}
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <TextInput
              ta="left"
              label="Nationality"
              withAsterisk
              radius={5}
              px={0}
              my={10}
              placeholder=" "
              {...form.getInputProps('nationality')}
            />
          </Grid.Col>
          <div className="bg-[#F5F8FF] pb-5 px-3 rounded-md my-3">
            <Grid>
              <Grid.Col span={6}>
                <Select
                  ta="left"
                  my={10}
                  label="ID Type"
                  withAsterisk
                  placeholder="Pick value"
                  defaultValue="Voter’s Card"
                  data={['Voter’s Card', 'International passport']}
                  {...form.getInputProps('identification_type')}
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <TextInput
                  ta="left"
                  label="ID Number"
                  withAsterisk
                  radius={5}
                  px={0}
                  my={10}
                  placeholder=" "
                  type="number"
                  {...form.getInputProps('identification_number')}
                />
              </Grid.Col>
              <Grid.Col span={12}>
                <TextInput
                  ta="left"
                  label="Place of issue"
                  withAsterisk
                  radius={5}
                  px={0}
                  my={10}
                  placeholder=""
                  {...form.getInputProps('identification_placeOfIssue')}
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <DateInput
                  ta="left"
                  valueFormat="YYYY-MM-DD"
                  label="Issue date"
                  clearable
                  withAsterisk
                  placeholder="DD-MM-YYYY"
                  {...form.getInputProps('identification_issueDate')}
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <DateInput
                  ta="left"
                  valueFormat="YYYY-MM-DD"
                  label="Expiry date"
                  clearable
                  withAsterisk
                  placeholder="DD-MM-YYYY"
                  {...form.getInputProps('identification_expiryDate')}
                />
              </Grid.Col>
            </Grid>
          </div>
          <div className="bg-[#F5F8FF] pb-5 px-3 rounded-md my-3">
            <Grid>
              <Grid.Col span={12}>
                <TextInput
                  ta="left"
                  label="Resident Permit Number (for non-Nigerians)"
                  radius={5}
                  px={0}
                  my={10}
                  placeholder=""
                  type="number"
                  {...form.getInputProps('residentPermit_number')}
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <DateInput
                  ta="left"
                  valueFormat="YYYY-MM-DD"
                  clearable
                  label="Issue date"
                  placeholder="DD-MM-YYYY"
                  {...form.getInputProps('residentPermit_issueDate')}
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <DateInput
                  ta="left"
                  valueFormat="YYYY-MM-DD"
                  label="Expiry date"
                  clearable
                  placeholder="DD-MM-YYYY"
                  {...form.getInputProps('residentPermit_expiryDate')}
                />
              </Grid.Col>
            </Grid>
          </div>
          <Grid.Col span={6}>
            <TextInput
              ta="left"
              label="Job Title"
              withAsterisk
              radius={5}
              px={0}
              my={10}
              placeholder=""
              {...form.getInputProps('jobTitle')}
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <TextInput
              ta="left"
              label="Occupation"
              withAsterisk
              radius={5}
              px={0}
              // py={20}
              // size=''
              my={10}
              placeholder=""
              {...form.getInputProps('occupation')}
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <Textarea
              ta="left"
              label="Home Address"
              withAsterisk
              radius={5}
              px={0}
              my={10}
              placeholder=" "
              {...form.getInputProps('address_location')}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              ta="left"
              label="City"
              withAsterisk
              radius={5}
              px={0}
              my={10}
              placeholder=" "
              {...form.getInputProps('address_city')}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              ta="left"
              label="Country"
              withAsterisk
              radius={5}
              px={0}
              my={10}
              placeholder=" "
              {...form.getInputProps('address_country')}
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <TextInput
              ta="left"
              label="Email Address"
              withAsterisk
              radius={5}
              px={0}
              my={10}
              placeholder=" "
              {...form.getInputProps('email')}
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <TextInput
              ta="left"
              label="Phone Number"
              withAsterisk
              radius={5}
              px={0}
              my={10}
              placeholder=" "
              type="number"
              {...form.getInputProps('phoneNumber')}
            />
          </Grid.Col>

          {/* Director Documents */}

          <Grid.Col span={12} mt={80}>
            <Text fw={500} size="18px" ta="left">
              Director Documents
            </Text>
          </Grid.Col>
          <Grid.Col span={12} mt={20} mb={10}>
            <Text ta="left" size="14px" fw={500}>
              Upload Passport Photograph 
            </Text>
            <Text ta="left" size="11px" mt={8}>
              Upload a scan of Shareholder’s Passport, or a scan of both sides of the ID Card. The
              data must be readable.
            </Text>

            <FileUpload
              {...form.getInputProps('passportPhoto')}
              onChange={(files) => fileUpload(files, 'passportPhoto')}
              disabled={isLoading}
            />
            <Text ta="left" size="11px" mt={8}>
              We support .pdf, .docx, .doc file formats and size up to 5 MB.
            </Text>
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              ta="left"
              label="Means of Identification"
              withAsterisk
              radius={5}
              px={0}
              my={10}
              value={form.getValues().identification_type}
              disabled
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              ta="left"
              label="ID Number"
              withAsterisk
              radius={5}
              px={0}
              my={10}
              value={form.getValues().identification_number}
              disabled
              placeholder=" "
            />
          </Grid.Col>
          <Grid.Col span={12} mt={20} mb={10}>
            <Text ta="left" size="14px" fw={500}>
              Upload ID
            </Text>
            <Text ta="left" size="11px" mt={8}>
              Upload a scan of Shareholder’s Passport, or a scan of both sides of the ID Card. The
              data must be readable.
            </Text>

            <FileUpload
              {...form.getInputProps('identification_doc')}
              onChange={(files) => fileUpload(files, 'identification_doc')}
              disabled={isLoading}
            />

            <Text ta="left" size="11px" mt={8}>
              We support .pdf, .docx, .doc file formats and size up to 5 MB.
            </Text>
          </Grid.Col>
          <Grid.Col span={12} mt={20} mb={10}>
            <Text ta="left" size="14px" fw={500}>
              Resident Permit (for non-Nigerians)
            </Text>
            <Text ta="left" size="11px" mt={8}>
              Upload a scan of Shareholder’s Passport, or a scan of both sides of the ID Card. The
              data must be readable.
            </Text>

            <FileUpload
              {...form.getInputProps('residentPermit_doc')}
              onChange={(files) => fileUpload(files, 'residentPermit_doc')}
              disabled={isLoading}
            />

            <Text ta="left" size="11px" mt={8}>
              We support .pdf, .docx, .doc file formats and size up to 5 MB.
            </Text>
          </Grid.Col>
          <Grid.Col span={12} mt={20} mb={10}>
            <Text ta="left" size="14px" fw={500}>
              Proof of Address (Utility Bill)
            </Text>
            <Text ta="left" size="11px" mt={8}>
              Upload a scan of Shareholder’s Passport, or a scan of both sides of the ID Card. The
              data must be readable.
            </Text>

            <FileUpload
              {...form.getInputProps('utilityBill')}
              onChange={(files) => fileUpload(files, 'utilityBill')}
              disabled={isLoading}
            />

            <Text ta="left" size="11px" mt={8}>
              We support .pdf, .docx, .doc file formats and size up to 5 MB.
            </Text>
          </Grid.Col>
        </Grid>
        <Box ta="left">
          <Button
            variant="outline"
            size="sm"
            radius="xl"
            color="#0798D0"
            fw={500}
            className="font-medium"
            mb={40}
            leftSection={<FaPlus size={14} />}
            type="submit">
            Add Director
          </Button>
        </Box>
        <div className="absolute -bottom-16 end-10">
          <Group justify="center" mt="xl">
            <Button
              bg="transparent"
              color="#0798D0"
              w={180}
              radius={12}
              mx={10}
              h={37}
              leftSection={<MdOutlineArrowBack size={16} />}
              variant="light"
              type="button"
              component="a"
              onClick={onPrevStep}>
              Back
            </Button>
            <Button
              variant="gradient"
              gradient={{ from: '#24E3F2', to: '#0798D0', deg: 90 }}
              w={180}
              radius={12}
              mx={10}
              onClick={handleSaveContinue}
              type="button"
              disabled={submit}>
              {submit ? <Loader size={20} /> : 'Save and Continue'}
            </Button>
          </Group>
        </div>
      </form>

      <AddDirectorsTable directors={directors} removeDirector={removeDirector} />
      </Box>
    </>
  );
};

export default AddDirectors;
