import { Anchor, Button } from '@mantine/core';
import { Navigate, useNavigate } from 'react-router';

const Step4 = () => {
  const navigate = useNavigate();
  return (
    <>
      <section>
        <div className="text-center ">
          <img src="/assets/success-img.png" alt="success image" className="w-2/4 mx-auto" />
          <h2 className="my-3 text-[#0798D0] font-bold">Successful</h2>
          <h2 className="my-3">Transaction is sent for approval</h2>
          <button
            onClick={(e) => {
              e.preventDefault();
              navigate('/dashboard/approvals');
            }}
            className="!text-black text-lg !underline mt-6 mb-3 cursor-pointer">
            Take me to approval
          </button>
        </div>
      </section>
    </>
  );
};

export default Step4;
