import {
  Grid,
  TextInput,
  Text,
  Select,
  Box,
  Button,
  Checkbox,
  Group,
  Loader,
  LoadingOverlay,
} from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { yupResolver } from 'mantine-form-yup-resolver';
import * as yup from 'yup';
import { FaPlus } from 'react-icons/fa';
import { MdOutlineArrowBack } from 'react-icons/md';
import { useEffect, useState } from 'react';
import { apiAddSignatories, apiRole, apiUpload } from '../../../api/ApiServices';
import AddSignatoriesTable from '../../cooperateAccount/component/AddSignatoriesTable';
import FileUpload from '../../common/FileUpload';
import dayjs from 'dayjs';
import { notifications } from '@mantine/notifications';

type Props = {
  onPrevStep: () => void;
  handleFinishClick: () => void;
};

const BusinessSignatories = ({ onPrevStep, handleFinishClick }: Props) => {
  const [step, setStep] = useState(4);
  const [selectedRoleName, setSelectedRoleName] = useState('');
  const [submit, setSubmit] = useState(false);
  const [roles, setRoles] = useState<{ name: string; description: string; _id: string }[]>([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const schema = yup.object().shape({
    firstName: yup.string().required('First Name is required'),
    lastName: yup.string().required('Last Name is required'),
    meta_dob: yup.date().required('Date of Birth is required').typeError('Invalid Date of Birth'),
    meta_gender: yup.string().required('Gender is required'),
    meta_nationality: yup.string().required('Nationality is required'),
    meta_residentPermit_number: yup.string().optional().nullable(),
    meta_residentPermit_issueDate: yup
      .date()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .nullable()
      .typeError('Invalid Issue Date'),
    meta_residentPermit_expiryDate: yup
      .date()
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .typeError('Invalid Expiry Date'),
    meta_phoneNumber: yup
      .number()
      .required('Phone Number is required')
      .typeError('Value must be a number'),
    meta_jobTitle: yup.string().required('Job Title is required'),
    email: yup
      .string()
      .required('Official Email Address is required')
      .email('Invalid email address'),
    meta_identification_kind: yup.string().required('ID type is required'),
    meta_identification_number: yup
      .string()
      .required('ID Number is required')
      .typeError('Value must be a number'),
    meta_passportPhoto: yup.mixed().required('Passport Photograph is required'),
    meta_identification_doc: yup.mixed().required('ID upload is required'),
    meta_residentPermit_doc: yup.mixed().optional().nullable(),
    meta_signatureDoc: yup.mixed().required('Signature is required'),
    meta_utilityBill: yup.mixed().required('Utility Bill is required'),
    termsAndConditions: yup.boolean().oneOf([true], 'You must agree to the Terms and Conditions'),
    informationAccuracy: yup
      .boolean()
      .oneOf([true], 'You must declare that the information provided is true and accurate'),
  });

  const form = useForm({
    initialValues: {
      firstName: '',
      lastName: '',
      meta_dob: '',
      meta_gender: '',
      meta_nationality: '',
      meta_residentPermit_number: '',
      meta_residentPermit_issueDate: '',
      meta_residentPermit_expiryDate: '',
      meta_phoneNumber: '',
      meta_jobTitle: '',
      email: '',
      meta_identification_kind: '',
      meta_identification_number: '',
      meta_identification_doc: null,
      meta_passportPhoto: null,
      meta_residentPermit_doc: null,
      meta_signatureDoc: null,
      meta_utilityBill: null,
      role: '',
      termsAndConditions: false,
      informationAccuracy: false,
    },
    validate: yupResolver(schema),
  });

  // fetch role
  const fetchData = async () => {
    try {
      const response = await apiRole();
      console.log(response);
      setRoles(response.data.roles);
    } catch (error: any) {
      setError(
        notifications.show({
          title: 'Something went wrong',
          color: 'red',
          message: error?.data?.message || 'An error occurred, please try again later.',
        })
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const [files, setFiles] = useState({});
  const FILE_SIZE_LIMIT = 5 * 1024 * 1024;
  const SUPPORTED_FILE_FORMATS = [
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ];
  const MAX_FILE_COUNT = 5;
  // Function to handle file upload to links
  const fileUpload = async (uploadFiles: File[], key: any) => {
    const filesToUpload = uploadFiles.length ? uploadFiles : null;

    if (filesToUpload === null) {
      form.setValues({ [key]: null });
      return;
    }
    // File size and type validation
    for (let i = 0; i < filesToUpload.length; i++) {
      const file = filesToUpload[i];
      if (file.size > FILE_SIZE_LIMIT) {
        setError(
          notifications.show({
            title: 'File size too large',
            color: 'red',
            autoClose: 20000,
            message: `File size of ${file.name} exceeds the limit of 5MB.`,
          })
        );
        return;
      }
      // File type validation
      if (!SUPPORTED_FILE_FORMATS.includes(file.type)) {
        setError(
          notifications.show({
            title: 'Unsupported file format',
            color: 'red',
            autoClose: 5000,
            message: `Unsupported file format for ${file.name}. Only PDF, DOC, DOCX files are allowed.`,
          })
        );
        return;
      }
    }

    // Maximum file count check
    if (filesToUpload.length > MAX_FILE_COUNT) {
      setError(
        notifications.show({
          title: 'Maximum number of files exceeded',
          color: 'red',
          autoClose: 20000,
          message: `You can upload a maximum of ${MAX_FILE_COUNT} files.`,
        })
      );
      return;
    }
    const formData = new FormData();

    //  console.log(files, key);

    for (let i = 0; i < filesToUpload.length; i++) {
      const file = filesToUpload[i];
      formData.append('files', file);
    }

    try {
      setIsLoading(true);

      const { data } = await apiUpload(formData);
      const links = data.links;
      form.setValues({ [key]: filesToUpload });
      console.log(key, { ...files, [key]: data.links });
      setFiles({ ...files, [key]: data.links });
      return links;
    } catch (error: any) {
      setError(
        notifications.show({
          title: 'Something went wrong',
          color: 'red',
          message: error?.data?.message || 'An error occurred, please try again later.',
        })
      );
    } finally {
      setIsLoading(false);
    }
  };

  const modObject = (obj: Record<string, any>) => {
    let modifiedObject: any = {};
    Object.entries(obj).map(([key, value]) => {
      const keySplit = key.split('_');
      const payloadKey = keySplit[2] || keySplit[1] || keySplit[0];
      if (
        value &&
        (payloadKey === 'dob' || payloadKey === 'expiryDate' || payloadKey === 'issueDate')
      ) {
        // if key is a date value, do the check here
        value = dayjs(value as string).format('YYYY-MM-DD');
      }
      if (payloadKey === 'informationAccuracy' || payloadKey === 'termsAndConditions') {
        // if key is informationAccuracy or termsAndConditions, skip them
        return;
      }

      if (keySplit[2]) {
        //meta_nextOfKin_firstName
        // init the nesedt object with an object to avoid error
        if (!modifiedObject[keySplit[0]]) modifiedObject[keySplit[0]] = {};
        if (!modifiedObject[keySplit[0]][keySplit[1]])
          modifiedObject[keySplit[0]][keySplit[1]] = {};

        modifiedObject[keySplit[0]][keySplit[1]][payloadKey] = value;
      } else if (keySplit[1]) {
        // init the nesedt object with an object to avoid error
        if (!modifiedObject[keySplit[0]]) modifiedObject[keySplit[0]] = {};

        modifiedObject[keySplit[0]][payloadKey] = value;
      } else {
        modifiedObject[payloadKey] = value;
      }
    });

    return modifiedObject;
  };

  const [directors, setDirectors] = useState<User[]>([]);

  const removeDirector = async (index: number) => {
    try {
      const directersCopy = [...directors];

      directersCopy.splice(index, 1);

      setDirectors(directersCopy);
      console.log(index, 'index to remove');
    } catch (error: any) {
      setError(
        notifications.show({
          title: 'Something went wrong',
          color: 'red',
          message: error?.data?.message || 'An error occurred, please try again later.',
        })
      );
      console.log(error);
    }
  };

  const addDirector = async (data: any) => {
    console.log(data, 'dtattat');
    try {
      console.log(form, 'form');
      console.log(data);
      if (form) {
        // replace file files in data into a link
        setDirectors([...directors, { ...data, ...files }]);
        console.log([...directors, { ...data, ...files }], 'form');

        // reset form and file uploads
        form.reset();
        form.setFieldValue('role', '');
        setFiles({});
      }
    } catch (error: any) {
      setError(
        notifications.show({
          title: 'Something went wrong',
          color: 'red',
          message: error?.data?.message || 'An error occurred, please try again later.',
        })
      );
    }
  };

  const handleSaveContinue = async () => {
    const modDirectors = directors.map((director) => modObject(director));
    console.log('modDirectors:', modDirectors);

    try {
      setSubmit(true);
      const id = localStorage.getItem('registeredId') || '';
      const resp = await apiAddSignatories(id, modDirectors);
      console.log('id', id, 'modDirectors', modDirectors);
      handleFinishClick();
    } catch (error: any) {
      setError(
        notifications.show({
          title: 'Something went wrong',
          color: 'red',
          message: error?.data?.message || error.message || 'An error occurred, please try again later.',
        })
      );
    } finally {
      setSubmit(false);
    }
  };

  return (
    <>
      <Box pos="relative">
        <LoadingOverlay
          zIndex={1000}
          visible={isLoading}
          overlayProps={{ radius: 'sm', blur: 1 }}
        />
        <Text size="lg" mt={20} fw={500} ta="left">
          Add Account Signatories
        </Text>
        <Text size="sm" ta="left">
          Make sure this is the same information as on your passport or ID, so we can verify it
          later on.
        </Text>
        <form onSubmit={form.onSubmit(addDirector)}>
          <Grid mt={20} mb={50} grow>
            <Grid.Col span={6}>
              <TextInput
                ta="left"
                label="First Name "
                withAsterisk
                radius={5}
                px={0}
                my={10}
                placeholder=""
                {...form.getInputProps('firstName')}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput
                ta="left"
                label="Last Name  "
                withAsterisk
                radius={5}
                px={0}
                my={10}
                placeholder=""
                {...form.getInputProps('lastName')}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <DateInput
                ta="left"
                valueFormat="DD-MMM-YYYY"
                label="Date of Birth"
                my={10}
                withAsterisk
                placeholder="DD-MM-YYYY"
                {...form.getInputProps('meta_dob')}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <Select
                ta="left"
                my={10}
                label="Gender"
                withAsterisk
                placeholder="Pick value"
                defaultValue="Female"
                data={['Female', 'Male']}
                {...form.getInputProps('meta_gender')}
              />
            </Grid.Col>
            <Grid.Col span={12}>
              <TextInput
                ta="left"
                label="Nationality"
                withAsterisk
                radius={5}
                px={0}
                my={10}
                placeholder=" "
                {...form.getInputProps('meta_nationality')}
              />
            </Grid.Col>
            <div className="bg-[#F5F8FF] pb-5 px-3 rounded-md my-3">
              <Grid>
                <Grid.Col span={12}>
                  <TextInput
                    ta="left"
                    label="Resident Permit Number (for non-Nigerians)"
                    radius={5}
                    px={0}
                    my={10}
                    placeholder=""
                    {...form.getInputProps('meta_residentPermit_number')}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <DateInput
                    ta="left"
                    valueFormat="DD-MMM-YYYY"
                    label="Permit Issue Date"
                    placeholder="DD-MM-YYYY"
                    clearable
                    {...form.getInputProps('meta_residentPermit_issueDate')}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <DateInput
                    ta="left"
                    valueFormat="DD-MMM-YYYY"
                    label="Permit Expiry Date"
                    placeholder="DD-MM-YYYY"
                    clearable
                    {...form.getInputProps('meta_residentPermit_expiryDate')}
                  />
                </Grid.Col>
              </Grid>
            </div>
            <Grid.Col span={6}>
              <TextInput
                ta="left"
                label="Phone Number "
                withAsterisk
                radius={5}
                px={0}
                my={10}
                placeholder=""
                type="number"
                {...form.getInputProps('meta_phoneNumber')}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput
                ta="left"
                label="Job Title"
                withAsterisk
                radius={5}
                px={0}
                my={10}
                placeholder=""
                {...form.getInputProps('meta_jobTitle')}
              />
            </Grid.Col>
            <Grid.Col span={12}>
              <TextInput
                ta="left"
                label="Official Email Address"
                withAsterisk
                radius={5}
                px={0}
                my={10}
                placeholder=""
                {...form.getInputProps('email')}
              />
            </Grid.Col>
            <Grid.Col span={12} mt={20} mb={10}>
              <Text ta="left" size="14px" fw={500}>
                Upload Passport Photograph 
              </Text>
              <Text ta="left" size="11px" mt={8}>
                Upload a scan of Shareholder’s Passport, or a scan of both sides of the ID Card. The
                data must be readable.
              </Text>

              <FileUpload
                {...form.getInputProps('meta_passportPhoto')}
                onChange={(files) => fileUpload(files, 'meta_passportPhoto')}
              />

              <Text ta="left" size="11px" mt={8}>
                We support .pdf, .docx, .doc file formats and size up to 5 MB.
              </Text>
            </Grid.Col>
            <Grid.Col span={6}>
              <Select
                ta="left"
                my={10}
                label="ID Type"
                withAsterisk
                placeholder="Pick value"
                data={['Voter’s Card', 'International passport', 'Others']}
                {...form.getInputProps('meta_identification_kind')}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput
                ta="left"
                label="ID Number"
                withAsterisk
                radius={5}
                px={0}
                my={10}
                placeholder=" "
                {...form.getInputProps('meta_identification_number')}
              />
            </Grid.Col>
            <Grid.Col span={12} mt={20} mb={10}>
              <Text ta="left" size="14px" fw={500}>
                Upload ID
              </Text>
              <Text ta="left" size="11px" mt={8}>
                Upload a scan of Shareholder’s Passport, or a scan of both sides of the ID Card. The
                data must be readable.
              </Text>

              <FileUpload
                {...form.getInputProps('meta_identification_doc')}
                onChange={(files) => fileUpload(files, 'meta_identification_doc')}
                disabled={isLoading}
              />

              <Text ta="left" size="11px" mt={8}>
                We support .pdf, .docx, .doc file formats and size up to 5 MB.
              </Text>
            </Grid.Col>
            <Grid.Col span={12} mt={20} mb={10}>
              <Text ta="left" size="14px" fw={500}>
                Resident Permit (for non-Nigerians)
              </Text>
              <Text ta="left" size="11px" mt={8}>
                Upload a scan of Shareholder’s Passport, or a scan of both sides of the ID Card. The
                data must be readable.
              </Text>

              <FileUpload
                {...form.getInputProps('meta_residentPermit_doc')}
                onChange={(files) => fileUpload(files, 'meta_residentPermit_doc')}
                disabled={isLoading}
              />
              <Text ta="left" size="11px" mt={8}>
                We support .pdf, .docx, .doc file formats and size up to 5 MB.
              </Text>
            </Grid.Col>
            <Grid.Col span={12} mt={20} mb={10}>
              <Text ta="left" size="14px" fw={500}>
                Upload Signature
              </Text>
              <Text ta="left" size="11px" mt={8}>
                Upload a scan of Shareholder’s Passport, or a scan of both sides of the ID Card. The
                data must be readable.
              </Text>

              <FileUpload
                {...form.getInputProps('meta_signatureDoc')}
                onChange={(files) => fileUpload(files, 'meta_signatureDoc')}
                disabled={isLoading}
              />

              <Text ta="left" size="11px" mt={8}>
                We support .pdf, .docx, .doc file formats and size up to 5 MB.
              </Text>
            </Grid.Col>
            <Grid.Col span={12} mt={20} mb={10}>
              <Text ta="left" size="14px" fw={500}>
                Proof of Address (Utility Bill)
              </Text>
              <Text ta="left" size="11px" mt={8}>
                Upload a scan of Shareholder’s Passport, or a scan of both sides of the ID Card. The
                data must be readable.
              </Text>

              <FileUpload
                {...form.getInputProps('meta_utilityBill')}
                onChange={(files) => fileUpload(files, 'meta_utilityBill')}
                disabled={isLoading}
              />

              <Text ta="left" size="11px" mt={8}>
                We support .pdf, .docx, .doc file formats and size up to 5 MB.
              </Text>
            </Grid.Col>

            {loading ? (
              <Loader size={22} />
            ) : (
              <Grid bg="#F5F8FF" p={12} mt={30} mb={20} style={{ borderRadius: '8px' }}>
                {roles.map((role, index) => (
                  <Grid.Col span={4} mt={10} key={index}>
                    <Checkbox
                      label={role.name}
                      fw={500}
                      className="capitalize"
                      value={role._id}
                      description={role.description}
                      {...form.getInputProps('role')}
                      checked={form.values.role === role._id}
                      onChange={() => {
                        form.setFieldValue('role', role._id); // Set the role ID in form state
                      }}
                    />
                  </Grid.Col>
                ))}
              </Grid>
            )}
          </Grid>
          <Box className="mt-4">
            <Checkbox
              className="text-xs text-start mb-6"
              label="By signing this form I confirm that i have read, understood and agreed 
            to the Terms and Conditions of Source Bank. "
              {...form.getInputProps('termsAndConditions')}
              checked={form.values.termsAndConditions}
            />
            <Checkbox
              className="text-xs text-start mb-12"
              label="I declare that all information provided to Source Microfinance Bank for
            account opening is true and accurate. I agree to comply with the account
            terms and conditions guiding this account, promptly update any changes
            to the information provided here, and provide necessary verification.
            I authorise Source Microfinance Bank to debit my account for corporate
            search to verify my business documents and process my data as required.

            By signing, I acknowledge understanding of account documents and agree
            to abide by their terms."
              {...form.getInputProps('informationAccuracy')}
              checked={form.values.informationAccuracy}
            />
          </Box>
          <div className="">
            <Button
              variant="outline"
              fw={500}
              size="sm"
              radius="xl"
              color="#0798D0"
              my={10}
              leftSection={<FaPlus size={14} />}
              type="submit"
              disabled={submit}>
              Add Signatories
            </Button>
          </div>

          <div className="absolute  -bottom-16 end-5">
            <Group justify="center" mt="xl">
              <Button
                bg="transparent"
                color="#0798D0"
                w={180}
                radius={12}
                mx={10}
                h={37}
                leftSection={<MdOutlineArrowBack size={16} />}
                variant="light"
                type="submit"
                component="a"
                onClick={onPrevStep}>
                Back
              </Button>
              <Button
                variant="gradient"
                gradient={{ from: '#24E3F2', to: '#0798D0', deg: 90 }}
                w={180}
                radius={12}
                mx={10}
                type="button"
                onClick={handleSaveContinue}
                disabled={submit}>
                {submit ? <Loader size={20} /> : 'Save and Continue'}
              </Button>
            </Group>
          </div>
        </form>

        <AddSignatoriesTable signatory={directors} removeDirector={removeDirector} roles={roles} />
      </Box>
    </>
  );
};

export default BusinessSignatories;
