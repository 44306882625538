import { createContext, useContext, useState } from 'react';
import { apiApprovalsCount } from '../components/api/ApiServices';
import { notifications } from '@mantine/notifications';

// Create a context to manage approval-related data and functions
const ApprovalContext = createContext<{
  count: number;
  getApprovalCount: () => Promise<void>;
}>({
  count: 0,
  getApprovalCount: async () => {},
});

// Create a hook to access the ApprovalContext
const useApproval = () => useContext(ApprovalContext);

// Create a component that provides approval-related data and functions
const ApprovalProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [count, setCount] = useState(0);

  const getApprovalCount = async () => {
    try {
      const response = await apiApprovalsCount();
      setCount(response.data.noOfPendingApprovals);
    } catch (error) {
      notifications.show({
        title: 'Something went wrong',
        color: 'red',
        message: 'An error occurred, please try again later.',
      });
    }
  };

  return (
    <ApprovalContext.Provider value={{ count, getApprovalCount }}>
      {children}
    </ApprovalContext.Provider>
  );
};

export { ApprovalContext, ApprovalProvider, useApproval };
