import numeral from 'numeral';
import { getCBNTransferFee } from '../../../fund-transfer/component/modal/Step2';
import { apiTranReceipt } from '../../../../../api/ApiServices';
import { notifications } from '@mantine/notifications';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import { useState } from 'react';
import { Loader } from '@mantine/core';

type Prop = {
  approvalData: Transaction[];
};

const Step1 = ({ approvalData }: Prop) => {
  const [loading, setLoading] = useState(false);
  const amount = Number(approvalData[0]?.amount || 0);
  const fee = amount > 0 ? getCBNTransferFee(amount, approvalData[0].beneficiaryBankCode) : 0;
  const total = amount;
  // const total = amount + fee;

  const handleDownload = async (id: string) => {
    setLoading(true);
    try {
      console.log('Starting download for ID:', id);

      const data = await apiTranReceipt(id);
      const url = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }));
      const hideLink = document.createElement('a');
      hideLink.style.display = 'none';
      hideLink.href = url;
      hideLink.download = `${approvalData[0].beneficiaryAccountName || ''} Receipt.pdf`;
      document.body.appendChild(hideLink);
      hideLink.click();
      document.body.removeChild(hideLink);
      setLoading(false);

      // const blob = new Blob([response.data], { type: 'application/pdf' });
      // console.log('Blob created', {
      //   size: blob.size,
      //   type: blob.type,
      // });

      // saveAs(blob, `receipt_${id}.pdf`);
      // console.log('saveAs called');
    } catch (error: any) {
      console.error('Error in handleDownload:', error);
      setLoading(false);

      notifications.show({
        title: 'Download Failed',
        color: 'red',
        message: error.message || 'An error occurred, please try again later.',
      });
    }
  };

  return (
    <section className="">
      <div className="grid grid-cols-2 mb-8 px-3">
        <img src="/assets/logo.png" alt="logo" />
        <h1 className="col-end-6 font-semibold text-xs my-auto">Transaction Details</h1>
      </div>
      <main className="px-8">
        <div className="text-center my-9">
          <p className="text-xs font-semibold">Transaction Amount</p>
          <h1 className="col-end-6 font-semibold text-xl mt-2">
            ₦{numeral(total).format('0,0.00')}
          </h1>
          <hr className="w-1/2 mx-auto mt-2" />
        </div>

        {approvalData[0].type !== 'credit' && (
          <div className="grid grid-cols-2  border-b-[1px] py-3 border-gray-200">
            <h1 className="text-xs ">Requested by</h1>
            <span className="text-xs col-end-6 font-semibold">
              {`${approvalData[0]?.initiator?.firstName} ${approvalData[0]?.initiator?.lastName}`}
            </span>
          </div>
        )}

        <div className="grid grid-cols-2  border-b-[1px] py-3 border-gray-200">
          <p className="text-xs ">
            {approvalData[0].type !== 'credit' ? 'Beneficiary' : 'Originating'} Bank
          </p>
          <p className="text-xs col-end-6 font-semibold">
            {approvalData[0]?.beneficiaryBankName || approvalData[0]?.originatorBankName || ''}
          </p>
        </div>

        <div className="grid grid-cols-1  border-b-[1px] py-3 border-gray-200">
          <p className="text-xs ">
            {approvalData[0].type !== 'credit' ? 'Beneficiary' : 'Originating'} Account number
          </p>
          <p className="text-xs col-end-11 font-semibold">
            {approvalData[0]?.beneficiaryAccountNumber || approvalData[0]?.originatorAccountNumber}
          </p>
        </div>
        <div className="grid grid-cols-1  border-b-[1px] py-3 border-gray-200">
          <p className="text-xs ">
            {approvalData[0].type !== 'credit' ? 'Beneficiary' : 'Originating'} Account name
          </p>
          <p className="text-xs col-end-6 font-semibold">
            {(approvalData[0].type !== 'credit'
              ? approvalData[0]?.beneficiaryAccountName
              : approvalData[0]?.originatorAccountName) || '-'}
          </p>
        </div>
        <div className="grid grid-cols-1  border-b-[1px] py-3 border-gray-200">
          <p className="text-xs ">Reference Number</p>
          <h1 className="text-xs col-end-6 font-semibold">
            {approvalData[0]?.processorId || approvalData[0]?.sessionID || approvalData[0]?._id}
          </h1>
        </div>
        <div className="grid grid-cols-2  border-b-[1px] py-3 border-gray-200">
          <p className="text-xs ">Date and Time</p>
          <h1 className="text-xs col-end-6 font-semibold">
            {approvalData[0]?.createdAt
              ? dayjs(approvalData[0]?.createdAt).format('ddd, MMM DD YYYY hh:mm A')
              : 'NA'}
          </h1>
        </div>
        <div className="grid grid-cols-2  border-b-[1px] py-3 border-gray-200">
          <p className="text-xs ">Status</p>
          <h1 className="text-xs col-end-6 font-semibold">{approvalData[0]?.status}</h1>
        </div>
        <div className="grid grid-cols-2  border-b-[1px] py-3 border-gray-200">
          <p className="text-xs ">Narration</p>
          <h1 className="text-xs col-end-6 font-semibold">{approvalData[0]?.narration}</h1>
        </div>
        <div className="grid grid-cols-1 w-2/5 mx-auto mt-5">
          {/* <div className="text-center ">
            <div className="border border-[#1E253F] border-opacity-40 cursor-pointer rounded-md w-[55px] h-[50px] card shadow-lg mx-auto p-3">
              <img
                src="/assets/dash/share-icon.png"
                alt="share icon"
                width={21}
                className="text-center mx-auto"
              />
            </div>
            <p className="text-xs mt-2"> Share Receipt</p>
          </div> */}
          {approvalData[0].status === 'successful' ? (
            <div className="text-center">
              <div
                className="border border-[#1E253F] border-opacity-40 cursor-pointer rounded-md w-[55px] h-[50px] card shadow-lg mx-auto p-3  "
                onClick={() => handleDownload(approvalData[0]._id)}>
                {loading ? (
                  <Loader size={16} />
                ) : (
                  <img
                    src="/assets/dash/download.png"
                    alt="download icon"
                    width={21}
                    className="text-center mx-auto"
                  />
                )}
              </div>
              <p className="text-xs mt-2"> Download Receipt</p>
            </div>
          ) : null}
        </div>
      </main>
    </section>
  );
};

export default Step1;
