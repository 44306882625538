import { Grid, TextInput, Textarea, Text, Select, Button, Group, Loader, Box, LoadingOverlay } from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { useForm } from '@mantine/form';
import dayjs from 'dayjs';
import { yupResolver } from 'mantine-form-yup-resolver';
import { useState } from 'react';
import { MdOutlineArrowBack } from 'react-icons/md';
import * as yup from 'yup';
import { apiUpload, apiAddDirectors } from '../../../api/ApiServices';
import FileUpload from '../../common/FileUpload';
import { notifications } from '@mantine/notifications';

const BusinessOwnerRep = ({ onNextStep, onPrevStep }: any) => {
  const [step, setStep] = useState(3);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [submit, setSubmit] = useState(false);

  const schema = yup.object().shape({
    firstName: yup.string().required('First Name is required'),
    lastName: yup.string().required('Last Name is required'),
    dob: yup.date().required('Date of Birth is required').typeError('Invalid Date of Birth'),
    gender: yup.string().required('Gender is required'),
    nationality: yup.string().required('Nationality is required'),
    identification_type: yup.string().required('ID Type is required'),
    identification_number: yup.string().required('ID Number is required'),
    identification_placeOfIssue: yup.string().required('Place of Issue is required'),
    identification_issueDate: yup
      .date()
      .required('Issue date is required')
      .typeError('Invalid Issue date'),
    identification_expiryDate: yup
      .date()
      .required('Expiry date is required')
      .typeError('Invalid Expiry Date'),
    residentPermit_number: yup.string().nullable().optional(),
    residentPermit_issueDate: yup
      .date()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .nullable()
      .typeError('Invalid Date'),
    residentPermit_expiryDate: yup
      .date()
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .typeError('Invalid Date'),
    bvn: yup.string().required('BVN is required'),
    email: yup.string().email('Invalid email').required('Email Address is required'),
    jobTitle: yup.string().required('Job Title is required'),
    occupation: yup.string().required('Occupation is required'),
    address_location: yup.string().required('Company Address is required'),
    address_city: yup.string().required('City is required'),
    address_country: yup.string().required('Country is required'),
    phoneNumber: yup.string().required('Phone Number is required'),
    meta_nextOfKin_firstName: yup.string().required('Next of Kin First Name is required'),
    meta_nextOfKin_lastName: yup.string().required('Next of Kin Last Name is required'),
    meta_nextOfKin_dob: yup
      .date()
      .required('Next of Kin Date of Birth is required')
      .typeError('Invalid Date'),
    meta_nextOfKin_gender: yup.string().required('Next of Kin Gender is required'),
    meta_nextOfKin_relationship: yup.string().required('Next of Kin Relationship is required'),
    meta_nextOfKin_phoneNumber: yup.string().required('Next of Kin Phone Number is required'),
    meta_nextOfKin_email: yup
      .string()
      .email('Invalid email')
      .required('Next of Kin Email Address is required'),
    meta_nextOfKin_address: yup.string().required('Next of Kin Contact Address is required'),
    passportPhoto: yup.mixed().required('Passport photo is required'),
    identification_doc: yup.mixed().required('ID document is required'),
    residentPermit_doc: yup.mixed().optional().nullable(),
    utilityBill: yup.mixed().required('Utility Bill is required'),
  });

  const form = useForm({
    initialValues: {
      firstName: '',
      lastName: '',
      dob: '',
      gender: '',
      nationality: '',
      identification_type: '',
      identification_number: '',
      identification_placeOfIssue: '',
      identification_issueDate: '',
      identification_expiryDate: '',
      residentPermit_number: '',
      residentPermit_issueDate: '',
      residentPermit_expiryDate: '',
      bvn: '',
      email: '',
      jobTitle: '',
      occupation: '',
      address_location: '',
      address_city: '',
      address_country: '',
      phoneNumber: '',
      // Next of Kin
      meta_nextOfKin_firstName: '',
      meta_nextOfKin_lastName: '',
      meta_nextOfKin_dob: '',
      meta_nextOfKin_gender: '',
      meta_nextOfKin_relationship: '',
      meta_nextOfKin_phoneNumber: '',
      meta_nextOfKin_email: '',
      meta_nextOfKin_address: '',
      // Business Owner Documents
      passportPhoto: null,
      identification_doc: null,
      residentPermit_doc: null,
      utilityBill: null,
    },

    validate: yupResolver(schema),
  });

  // Function to handle file upload
  const [files, setFiles] = useState({});
  const FILE_SIZE_LIMIT = 5 * 1024 * 1024;
  const SUPPORTED_FILE_FORMATS = [
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ];
  const MAX_FILE_COUNT = 5;
  // Function to handle file upload
  const fileUpload = async (uploadFiles: File[], key: any) => {
    const filesToUpload = uploadFiles.length ? uploadFiles : null;

    if (filesToUpload === null) {
      form.setValues({ [key]: null });
      return;
    }
    // File size and type validation
    for (let i = 0; i < filesToUpload.length; i++) {
      const file = filesToUpload[i];
      if (file.size > FILE_SIZE_LIMIT) {
        setError(
          notifications.show({
            title: 'File size too large',
            color: 'red',
            autoClose: 20000,
            message: `File size of ${file.name} exceeds the limit of 5MB.`,
          })
        );
        return;
      }
      // File type validation
      if (!SUPPORTED_FILE_FORMATS.includes(file.type)) {
        setError(
          notifications.show({
            title: 'Unsupported file format',
            color: 'red',
            autoClose: 7000,
            message: `Unsupported file format for ${file.name}. Only PDF, DOC, DOCX files are allowed.`,
          })
        );
        return;
      }
    }

    // Maximum file count check
    if (filesToUpload.length > MAX_FILE_COUNT) {
      setError(
        notifications.show({
          title: 'Maximum number of files exceeded',
          color: 'red',
          autoClose: 20000,
          message: `You can upload a maximum of ${MAX_FILE_COUNT} files.`,
        })
      );
      return;
    }
    const formData = new FormData();

    //  console.log(files, key);

    for (let i = 0; i < filesToUpload.length; i++) {
      const file = filesToUpload[i];
      formData.append('files', file);
    }

    try {
      setIsLoading(true);

      const { data } = await apiUpload(formData);
      const links = data.links;
      form.setValues({ [key]: filesToUpload });
      console.log(key, { ...files, [key]: data.links });
      setFiles({ ...files, [key]: data.links });
      return links;
    } catch (error: any) {
      setError(
        notifications.show({
          title: 'Something went wrong',
          color: 'red',
          message: error?.data?.message || 'An error occurred, please try again later.',
        })
      );
    } finally {
      setIsLoading(false);
    }
  };

  // const isObject = (variable: any) => {
  //   if (!variable) return false;

  //   return Object.prototype.toString.call(variable) === '[object Object]';
  // };

  // const filterEmptyFields = (obj: any): any => {
  //   if (obj && isObject(obj)) {
  //     return Object.entries(obj)
  //       .filter(([_, v]) => v != null && v != '')
  //       .reduce(
  //         (acc, [k, v]) => ({
  //           ...acc,
  //           [k]: v === Object(v) ? filterEmptyFields(v) : v,
  //         }),
  //         {}
  //       );
  //   }

  //   return obj;
  // };

  const modObject = (obj: Record<string, any>) => {
    let modifiedObject: any = {};
    Object.entries(obj).map(([key, value]) => {
      const keySplit = key.split('_');
      const payloadKey = keySplit[2] || keySplit[1] || keySplit[0];
      if (
        value &&
        (payloadKey === 'dob' || payloadKey === 'expiryDate' || payloadKey === 'issueDate')
      ) {
        // if key is a date value, do the check here
        value = dayjs(value as string).format('YYYY-MM-DD');
      }

      if (keySplit[2]) {
        //meta_nextOfKin_firstName
        // init the nesedt object with an object to avoid error
        if (!modifiedObject[keySplit[0]]) modifiedObject[keySplit[0]] = {};
        if (!modifiedObject[keySplit[0]][keySplit[1]])
          modifiedObject[keySplit[0]][keySplit[1]] = {};

        modifiedObject[keySplit[0]][keySplit[1]][payloadKey] = value;
      } else if (keySplit[1]) {
        // init the nesedt object with an object to avoid error
        if (!modifiedObject[keySplit[0]]) modifiedObject[keySplit[0]] = {};

        modifiedObject[keySplit[0]][payloadKey] = value;
      } else {
        modifiedObject[payloadKey] = value;
      }
    });

    return modifiedObject;
  };

  const handleSaveContinue = async (data: any) => {
    try {
      const modData = modObject({ ...data, ...files });
      console.log(modData);
      setSubmit(true);
      const id = localStorage.getItem('registeredId') || '';
      const resp = await apiAddDirectors(id, [modData]);
      localStorage.setItem(`addDirectors${step}`, JSON.stringify(data));
      onNextStep();
    } catch (error: any) {
      setError(
        notifications.show({
          title: 'Something went wrong',
          color: 'red',
          message: error?.data?.message || 'An error occurred, please try again later.',
        })
      );
      console.log('Error occurred while adding directors:', error);
    } finally {
      setSubmit(false);
    }
  };

  return (
    <>
     <Box pos="relative">
        <LoadingOverlay
          zIndex={1000}
          visible={isLoading}
          overlayProps={{ radius: 'sm', blur: 1 }}
        />

      <Text size="lg" mt={20} fw={500} ta="left">
        Business Owner/Representative Details
      </Text>
      <Text size="sm" ta="left">
        Make sure this is the same information as on their passport or ID, so we can verify it later
        on.
      </Text>
      <form onSubmit={form.onSubmit(handleSaveContinue)}>
        <Grid mt={20} mb={50} grow>
          <Grid.Col span={6}>
            <TextInput
              ta="left"
              label="First Name "
              withAsterisk
              radius={5}
              px={0}
              my={10}
              placeholder=""
              {...form.getInputProps('firstName')}
            />
          </Grid.Col>

          <Grid.Col span={6}>
            <TextInput
              ta="left"
              label="Last Name  "
              withAsterisk
              radius={5}
              px={0}
              my={10}
              placeholder=""
              {...form.getInputProps('lastName')}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <DateInput
              ta="left"
              valueFormat="DD-MMM-YYYY"
              label="Date of Birth"
              clearable
              my={10}
              withAsterisk
              placeholder="DD-MM-YYYY"
              {...form.getInputProps('dob')}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <Select
              ta="left"
              my={10}
              label="Gender"
              withAsterisk
              placeholder="Pick value"
              defaultValue="Female"
              data={['Female', 'Male']}
              {...form.getInputProps('gender')}
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <TextInput
              ta="left"
              label="Nationality"
              withAsterisk
              radius={5}
              px={0}
              my={10}
              placeholder=" "
              {...form.getInputProps('nationality')}
            />
          </Grid.Col>

          <div className="bg-[#F5F8FF] pb-5 px-3 rounded-md my-3">
            <Grid>
              <Grid.Col span={6}>
                <Select
                  ta="left"
                  my={10}
                  label="ID Type"
                  withAsterisk
                  placeholder="Pick value"
                  data={['Voter’s Card', 'International passport', 'Others']}
                  {...form.getInputProps('identification_type')}
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <TextInput
                  ta="left"
                  label="ID Number"
                  withAsterisk
                  radius={5}
                  px={0}
                  my={10}
                  placeholder=" "
                  {...form.getInputProps('identification_number')}
                />
              </Grid.Col>
              <Grid.Col span={12}>
                <TextInput
                  ta="left"
                  label="Place of issue"
                  withAsterisk
                  radius={5}
                  px={0}
                  my={10}
                  placeholder=""
                  {...form.getInputProps('identification_placeOfIssue')}
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <DateInput
                  ta="left"
                  valueFormat="DD-MMM-YYYY"
                  label="Issue date"
                  withAsterisk
                  clearable
                  placeholder="DD-MM-YYYY"
                  {...form.getInputProps('identification_issueDate')}
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <DateInput
                  ta="left"
                  valueFormat="DD-MMM-YYYY"
                  clearable
                  label="Expiry date"
                  withAsterisk
                  placeholder="DD-MM-YYYY"
                  {...form.getInputProps('identification_expiryDate')}
                />
              </Grid.Col>
            </Grid>
          </div>
          <div className="bg-[#F5F8FF] pb-5 px-3 rounded-md my-3">
            <Grid>
              <Grid.Col span={12}>
                <TextInput
                  ta="left"
                  label="Resident Permit Number (for non-Nigerians)"
                  radius={5}
                  px={0}
                  my={10}
                  placeholder=""
                  {...form.getInputProps('residentPermit_number')}
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <DateInput
                  ta="left"
                  valueFormat="DD-MMM-YYYY"
                  label="Issue date"
                  clearable
                  placeholder="DD-MM-YYYY"
                  {...form.getInputProps('residentPermit_issueDate')}
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <DateInput
                  ta="left"
                  clearable
                  valueFormat="DD-MMM-YYYY"
                  label="Expiry date"
                  placeholder="DD-MM-YYYY"
                  {...form.getInputProps('residentPermit_expiryDate')}
                />
              </Grid.Col>
            </Grid>
          </div>
          <Grid.Col span={6}>
            <TextInput
              ta="left"
              label="BVN "
              withAsterisk
              radius={5}
              px={0}
              my={10}
              placeholder=""
              {...form.getInputProps('bvn')}
            />
          </Grid.Col>

          <Grid.Col span={6}>
            <TextInput
              ta="left"
              label="Job Title"
              withAsterisk
              radius={5}
              px={0}
              my={10}
              placeholder=""
              {...form.getInputProps('jobTitle')}
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <TextInput
              ta="left"
              label="Occupation"
              withAsterisk
              radius={5}
              px={0}
              my={10}
              placeholder=""
              {...form.getInputProps('occupation')}
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <Textarea
              ta="left"
              label="Company Address"
              withAsterisk
              radius={5}
              px={0}
              my={10}
              placeholder=" "
              {...form.getInputProps('address_location')}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              ta="left"
              label="City"
              withAsterisk
              radius={5}
              px={0}
              my={10}
              placeholder=" "
              {...form.getInputProps('address_city')}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              ta="left"
              label="Country"
              withAsterisk
              radius={5}
              px={0}
              my={10}
              placeholder=" "
              {...form.getInputProps('address_country')}
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <TextInput
              ta="left"
              label="Email Address"
              withAsterisk
              radius={5}
              px={0}
              my={10}
              placeholder=" "
              {...form.getInputProps('email')}
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <TextInput
              ta="left"
              label="Phone Number"
              withAsterisk
              radius={5}
              px={0}
              my={10}
              placeholder=" "
              {...form.getInputProps('phoneNumber')}
            />
          </Grid.Col>

          {/* next of kin */}

          <Grid.Col span={12} mt={80}>
            <Text fw={500} size="18px" ta="left">
              Next of Kin
            </Text>
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              ta="left"
              label="First Name "
              withAsterisk
              radius={5}
              px={0}
              my={10}
              placeholder=""
              {...form.getInputProps('meta_nextOfKin_firstName')}
            />
          </Grid.Col>

          <Grid.Col span={6}>
            <TextInput
              ta="left"
              label="Last Name  "
              withAsterisk
              radius={5}
              px={0}
              my={10}
              placeholder=""
              {...form.getInputProps('meta_nextOfKin_lastName')}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <DateInput
              ta="left"
              valueFormat="DD-MMM-YYYY"
              label="Date of Birth"
              clearable
              my={10}
              withAsterisk
              placeholder="DD-MM-YYYY"
              {...form.getInputProps('meta_nextOfKin_dob')}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <Select
              ta="left"
              my={10}
              label="Gender"
              withAsterisk
              placeholder="Pick value"
              defaultValue="Female"
              data={['Female', 'Male']}
              {...form.getInputProps('meta_nextOfKin_gender')}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              ta="left"
              label="Relationship "
              withAsterisk
              radius={5}
              px={0}
              my={10}
              placeholder=""
              {...form.getInputProps('meta_nextOfKin_relationship')}
            />
          </Grid.Col>

          <Grid.Col span={6}>
            <TextInput
              ta="left"
              label="Phone Number  "
              withAsterisk
              radius={5}
              px={0}
              my={10}
              placeholder=""
              {...form.getInputProps('meta_nextOfKin_phoneNumber')}
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <TextInput
              ta="left"
              label="Email Address"
              withAsterisk
              radius={5}
              px={0}
              my={10}
              placeholder=" "
              {...form.getInputProps('meta_nextOfKin_email')}
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <TextInput
              ta="left"
              label="Contact Address"
              withAsterisk
              radius={5}
              px={0}
              my={10}
              placeholder=" "
              {...form.getInputProps('meta_nextOfKin_address')}
            />
          </Grid.Col>

          <Grid.Col span={12} mt={80}>
            <Text fw={500} size="18px" ta="left">
              Business Owner Documents
            </Text>
          </Grid.Col>
          <Grid.Col span={12} mt={20} mb={10}>
            <Text ta="left" size="14px" fw={500}>
              Upload Passport Photograph 
            </Text>
            <Text ta="left" size="11px" mt={8}>
              Upload a scan of Shareholder’s Passport, or a scan of both sides of the ID Card. The
              data must be readable.
            </Text>
            <FileUpload
              {...form.getInputProps('passportPhoto')}
              onChange={(files) => fileUpload(files, 'passportPhoto')}
              disabled={isLoading}
            />

            <Text ta="left" size="11px" mt={8}>
              We support .pdf, .docx, .doc file formats and size up to 5 MB.
            </Text>
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              ta="left"
              label="Means of Identification"
              withAsterisk
              radius={5}
              px={0}
              my={10}
              value={form.getValues().identification_type}
              disabled
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              ta="left"
              label="ID Number"
              withAsterisk
              radius={5}
              my={10}
              value={form.getValues().identification_number}
              disabled
            />
          </Grid.Col>
          <Grid.Col span={12} mt={20} mb={10}>
            <Text ta="left" size="14px" fw={500}>
              Upload ID
            </Text>
            <Text ta="left" size="11px" mt={8}>
              Upload a scan of Shareholder’s Passport, or a scan of both sides of the ID Card. The
              data must be readable.
            </Text>

            <FileUpload
              {...form.getInputProps('identification_doc')}
              onChange={(files) => fileUpload(files, 'identification_doc')}
              disabled={isLoading}
            />

            <Text ta="left" size="11px" mt={8}>
              We support .pdf, .docx, .doc file formats and size up to 5 MB.
            </Text>
          </Grid.Col>
          <Grid.Col span={12} mt={20} mb={10}>
            <Text ta="left" size="14px" fw={500}>
              Resident Permit (for non-Nigerians)
            </Text>
            <Text ta="left" size="11px" mt={8}>
              Upload a scan of Shareholder’s Passport, or a scan of both sides of the ID Card. The
              data must be readable.
            </Text>

            <FileUpload
              {...form.getInputProps('residentPermit_doc')}
              onChange={(files) => fileUpload(files, 'residentPermit_doc')}
              disabled={isLoading}
            />

            <Text ta="left" size="11px" mt={8}>
              We support .pdf, .docx, .doc file formats and size up to 5 MB.
            </Text>
          </Grid.Col>
          <Grid.Col span={12} mt={20} mb={10}>
            <Text ta="left" size="14px" fw={500}>
              Proof of Address (Utility Bill)
            </Text>
            <Text ta="left" size="11px" mt={8}>
              Upload a scan of Shareholder’s Passport, or a scan of both sides of the ID Card. The
              data must be readable.
            </Text>

            <FileUpload
              {...form.getInputProps('utilityBill')}
              onChange={(files) => fileUpload(files, 'utilityBill')}
              disabled={isLoading}
            />

            <Text ta="left" size="11px" mt={8}>
              We support .pdf, .docx, .doc file formats and size up to 5 MB.
            </Text>
          </Grid.Col>
        </Grid>
        <Group justify="center" mt="xl">
          <Button
            bg="transparent"
            color="#0798D0"
            w={180}
            radius={12}
            mx={10}
            h={37}
            leftSection={<MdOutlineArrowBack size={16} />}
            variant="light"
            type="button"
            component="a"
            onClick={onPrevStep}>
            Back
          </Button>
          <Button
            variant="gradient"
            gradient={{ from: '#24E3F2', to: '#0798D0', deg: 90 }}
            w={180}
            radius={12}
            mx={10}
            type="submit"
            disabled={submit}>
            {submit ? <Loader size={20} /> : 'Save and Continue'}
          </Button>
        </Group>
      </form>
      </Box>
    </>
  );
};

export default BusinessOwnerRep;
