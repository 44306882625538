import { fetchData } from './MainServices';

interface ApiStructure<T> {
  headers: any;
  links: any;
  status: 'successful' | 'failed';
  message: string;
  data: T;
}
export const constructApiUrl = (baseUrl: string, params: any) => {
  const searchParams = new URLSearchParams();

  // Loop through the params object and add each parameter to the URL
  for (const key in params) {
    // eslint-disable-next-line no-prototype-builtins
    if (params?.hasOwnProperty(key) && params[key] !== undefined) {
      searchParams.append(key, params[key]);
    }
  }

  // Use the URLSearchParams toString method to get the query string
  const queryString = searchParams.toString();

  // Construct the full URL
  const apiUrl = `${baseUrl}${queryString ? `?${queryString}` : ''}`;

  return apiUrl;
};

// register account
export async function apiRegister(payload: any) {
  return fetchData<ApiStructure<{ _id: string }>>({
    url: '/auth/register',
    method: 'post',
    data: payload,
  });
}
// token verification
export async function apiToken(payload: any, id: string) {
  return fetchData<ApiStructure<{ _id: string; status: string }>>({
    url: `/auth/register/token/${encodeURIComponent(id)}`,
    method: 'post',
    data: payload,
  });
}
// resend token
export async function apiresendToken(id: string) {
  return fetchData<ApiStructure<{ _id: string; status: string }>>({
    url: `/auth/register/resend-token/${encodeURIComponent(id)}`,
    method: 'get',
  });
}
// upload document
export async function apiUpload(payload: FormData) {
  return fetchData<
    ApiStructure<{
      links: [];
      _id: string;
      status: string;
    }>
  >({
    url: '/upload',
    method: 'post',
    data: payload,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
    },
  });
}

// file submission
export async function apiFileSubmit(id: string, data: any) {
  return fetchData<ApiStructure<{ _id: string; status: string }>>({
    url: `/auth/register/document/${encodeURIComponent(id)}`,
    method: 'post',
    data,
  });
}

// add directors || business owners || executives
export async function apiAddDirectors(id: string, data: any) {
  return fetchData<ApiStructure<{ _id: string; status: string }>>({
    url: `/auth/register/stakeholder/${encodeURIComponent(id)}`,
    method: 'post',
    data,
  });
}

// role
export async function apiRole() {
  return fetchData<
    ApiStructure<{
      roles: [];
      _id: string;
      status: string;
    }>
  >({
    url: '/role',
    method: 'get',
  });
}

// add signatories || sole-proprietorship || non registered || resgistered
export async function apiAddSignatories(id: string, data: any) {
  try {
    const response = await fetchData<ApiStructure<{ _id: string; status: string }>>({
      url: `/auth/register/signatory/${encodeURIComponent(id)}`,
      method: 'post',
      data,
    });
    // Clear local storage after successful API call
    localStorage.clear();
    return response;
  } catch (error) {
    console.error('Error adding signatories:', error);
    throw error;
  }
}

// create password and confirm passwrod || reset password
export async function apiResetPassword(data: any) {
  return fetchData<ApiStructure<{ _id: string; status: string; is2FAEnabled: boolean }>>({
    url: '/auth/reset-password',
    method: 'post',
    data,
  });
}

// enable 2fa
export async function apiEnable2fa(data: any) {
  return fetchData<ApiStructure<{ qrUrl: string; secret: string }>>({
    url: '/auth/enable-2fa',
    method: 'post',
    data,
  });
}
// enable 2fa
export async function apiForgetPassword(data: any) {
  return fetchData<ApiStructure<{ _id: string; status: string }>>({
    url: '/auth/forgot-password',
    method: 'post',
    data,
  });
}
// verify 2fa code
export async function apiVerify2fa(data: any) {
  return fetchData<ApiStructure<{ _id: string; status: string }>>({
    url: '/auth/verify-2fa',
    method: 'post',
    data,
  });
}

// login
export async function apiLogin(data: any) {
  return fetchData<ApiStructure<IUserResp>>({
    url: '/auth/login',
    method: 'post',
    data,
    withCredentials: true,
  });
}

// api logout
export async function apiLogout() {
  return fetchData<ApiStructure<{ status: string; message: string }>>({
    url: '/auth/logout',
    method: 'get',
    withCredentials: true,
  });
}

// dashboard api emdpoint

// get stats
export async function apiGetStats(startDate?: string, endDate?: string) {
  const params: Record<string, string> = {};
  if (startDate) {
    params.startDate = startDate;
  }
  if (endDate) {
    params.endDate = endDate;
  }

  return fetchData<ApiStructure<StatsResponse>>({
    url: '/dashboard/stats',
    method: 'get',
    params,
  });
}

// get all business account
export async function apiAllAccount() {
  return fetchData<ApiStructure<BalanceData>>({
    url: '/accounts',
    method: 'get',
  });
}

// get account and balance
export async function apiAccount() {
  return fetchData<ApiStructure<{ accounts: Account }>>({
    url: '/accounts/account',
    method: 'get',
  });
}

// transfer single
export async function apiTransferSingle(payload: any) {
  return fetchData<ApiStructure<Transaction>>({
    url: '/transfers/single',
    method: 'post',
    data: payload,
  });
}

// api transfer bulk payment
export async function apiBulkTransfer(payload: any) {
  return fetchData<ApiStructure<Beneficiary>>({
    url: '/transfers/bulk',
    method: 'post',
    data: payload,
  });
}

// get all transactions by status
export async function apiTransaction(params: object) {
  return fetchData<ApiStructure<{ transactions: Transaction[]; paging: Paging }>>({
    url: constructApiUrl('/transactions', params),
    method: 'get',
  });
}
// get transactions receipt
export async function apiTranReceipt(_id: string) {
  return fetchData<any>({
    url: `/transactions/receipt/${_id}`,
    method: 'get',
    responseType: 'blob',
  });
  // return response;
}

// get statement of account

export async function apiStatementReceipt(queryParams: string, accountId: string) {
  return fetchData<any>({
    url: `/accounts/statement/${accountId}?${queryParams}`,
    method: 'get',
    responseType: 'blob',
  });
}

// get frequent beneficiaries transactions
export async function apiFrequentBeneficiary() {
  return fetchData<ApiStructure<{ beneficiaries: { accountName: string; frequency: number }[] }>>({
    url: '/transfers/beneficiaries',
    method: 'get',
  });
}

// switch between business account
export async function apiSwitchAccount(_id: string) {
  return fetchData<ApiStructure<BalanceData>>({
    url: `/accounts/switch/${_id}`,
    method: 'get',
  });
}

// get all approvals
export async function apiApproval(params: object) {
  return fetchData<ApiStructure<{ transactions: Transaction[]; paging: Paging }>>({
    url: constructApiUrl('/approvals', params),
    method: 'get',
  });
}

// accept approval
export async function apiAcceptApproval(payload: any) {
  return fetchData<ApiStructure<ApprovalsRequest>>({
    url: '/approvals/accept',
    method: 'post',
    data: payload,
  });
}

// reject or decline approval
export async function apiDeclineApproval(payload: any) {
  return fetchData<ApiStructure<ApprovalsRequest>>({
    url: '/approvals/reject',
    method: 'post',
    data: payload,
  });
}

// api to get all banks
export async function apiGetBanks() {
  return fetchData<
    ApiStructure<{
      banks: [
        {
          name: string;
          code: string;
        }
      ];
    }>
  >({
    url: '/banks/list',
    method: 'get',
  });
}
// api to account name
export async function apiGetAccountName(payload: any) {
  return fetchData<
    ApiStructure<{
      accountName: string;
      accountNumber: string;
      nameEnquiryRef: string;
    }>
  >({
    url: '/banks/name-enquiry',
    method: 'post',
    data: payload,
  });
}
// api to get all categories
export async function apiGetCategories() {
  return fetchData<
    ApiStructure<{
      categories: [{ name: string }];
    }>
  >({
    url: '/banks/list/categories',
    method: 'get',
  });
}

// api to get all user accounts associated to the business
export async function apiAllBusinessAcc() {
  return fetchData<ApiStructure<Signatories[]>>({
    url: '/signatories',
    method: 'get',
  });
}
// api to switch account from enable to disable vise versa
export async function apiSwitchAccess(payload: any) {
  return fetchData<ApiStructure<{ status: string; message: string }>>({
    url: '/signatories/access',
    method: 'patch',
    data: payload,
  });
}

export async function apiFetchCurrentUser() {
  return fetchData<ApiStructure<IUserResp>>({
    url: '/signatories/current',
    method: 'get',
  });
}

// api to get pending approvals / count
export async function apiApprovalsCount() {
  return fetchData<ApiStructure<{ noOfPendingApprovals: number }>>({
    url: '/approvals/count',
    method: 'get',
  });
}
