import { Modal } from '@mantine/core';
import { useState } from 'react';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';

type Prop = {
  opened: boolean;
  open: () => void;
  close: () => void;
};

export type StepType = {
  step: number;
  title: string;
};

const Index = ({ opened, close }: Prop) => {
  const [activeStep, setActiveStep] = useState(1);
  const [formData, setFormData] = useState<Beneficiary | null>();

  const steps = [
    { step: 1, title: 'Transfer to someone' },
    { step: 2, title: 'Review details of your transfer' },
    { step: 3, title: '' },
    { step: 4, title: '' },
  ];

  const nextStep = () => {
    setActiveStep((current) => {
      if (current === steps.length) {
        return 1;
      }
      return Math.min(current + 1, steps.length);
    });
  };
  const prevStep = () => setActiveStep((current) => Math.max(current - 1, 1));

  const handleClose = () => {
    setActiveStep(1);
    close();
    setFormData(null);
  };

  const getCurrentStepTitle = (step: number) => steps.find((s) => s.step === step)?.title || '';

  const handleFormDataChange = (data: Beneficiary) => {
    setFormData(data);
  };
  return (
    <>
      <Modal
        size={513}
        opened={opened}
        onClose={handleClose}
        title={getCurrentStepTitle(activeStep)}
        closeOnClickOutside={false}
        centered>
        {/* Render the appropriate step based on activeStep */}
        {activeStep === 1 && (
          <Step1
            onNext={nextStep}
            onFormDataChange={handleFormDataChange}
            formData={formData as Beneficiary}
          />
        )}
        {activeStep === 2 && (
          <Step2 onPrev={prevStep} onNext={nextStep} formData={formData as Beneficiary} />
        )}
        {activeStep === 3 && (
          <Step3 onPrev={prevStep} onNext={nextStep} formData={formData as Beneficiary} />
        )}
        {activeStep === 4 && <Step4 />}
      </Modal>
    </>
  );
};

export default Index;
