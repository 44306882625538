import { FileInput, FileInputProps } from '@mantine/core';
import React from 'react';
import { IoDocumentTextOutline } from 'react-icons/io5';

const FileUpload: React.FC<FileInputProps<true>> = ({ ...props }) => {
  return (
    <FileInput
      leftSection={<IoDocumentTextOutline />}
      placeholder="Upload your document here"
      clearable
      withAsterisk
      accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      multiple
      styles={{
        wrapper: {},
        input: {
          backgroundColor: '#FAFAFE',
          border: 'dashed 1px',
          color: 'gray',
        },
      }}
      mt={12}
      {...props}
    />
  );
};

export default FileUpload;
