import { Box, Button, Group, List, Loader, LoadingOverlay, Text } from '@mantine/core';
import DocumentUpload from '../../common/DocumentUpload';
import FileUpload from '../../common/FileUpload';
import { notifications } from '@mantine/notifications';
import { useState, useEffect } from 'react';
import { useForm, yupResolver } from '@mantine/form';
import * as yup from 'yup';
import { apiUpload, apiFileSubmit } from '../../../api/ApiServices';
import { MdOutlineArrowBack } from 'react-icons/md';
import { fileUpload } from '../../common/fileUtils';

const NonRegisterDoc = ({ onNextStep, onPrevStep }: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [submit, setSubmit] = useState(false);

  const [step, setStep] = useState(() => {
    const savedStep = localStorage.getItem('currentStep');
    return savedStep ? parseInt(savedStep, 10) : 2;
  });

  useEffect(() => {
    localStorage.setItem('currentStep', step.toString());
  }, [step]);

  const schema = yup.object().shape({
    executiveMembersAffidavit: yup
      .mixed()
      .required('Affidavit is required ') as yup.MixedSchema<File | null>,
    boardResolution: yup
      .mixed()
      .required('Board Resolution is required ') as yup.MixedSchema<File | null>,
    utilityBill: yup.mixed().required('Utility Bill is required ') as yup.MixedSchema<File | null>,
    groupConstitution: yup
      .mixed()
      .required('Specialized License is required ') as yup.MixedSchema<File | null>,
  });

  const form = useForm({
    initialValues: {
      executiveMembersAffidavit: null,
      boardResolution: null,
      utilityBill: null,
      groupConstitution: null,
    },

    validate: yupResolver(schema),
  });

  const [payload, setPayload] = useState({});

  const handleFileUpload = (files: File[], key: string) => {
    fileUpload(files, key, form, setIsLoading, setPayload);
  };

  const submitLinks = async (event: any) => {
    try {
      setSubmit(true);
      const id = localStorage.getItem('registeredId') || '';
      const resp = await apiFileSubmit(id, payload);
      console.log(resp);
      onNextStep();
    } catch (error: any) {
      notifications.show({
        title: 'Something went wrong',
        color: 'red',
        message:
          error?.data?.message || error.message || 'An error occurred, please try again later.',
      });
    } finally {
      setSubmit(false);
    }
  };
  return (
    <>
      <Box pos="relative">
        <LoadingOverlay
          zIndex={1000}
          visible={isLoading}
          overlayProps={{ radius: 'sm', blur: 1 }}
        />
        <form onSubmit={form.onSubmit((e) => submitLinks(e))}>
          <Box mb={50} mt={20}>
            <Text ta="left" fw={500}>
              Provide us with the following Documents
            </Text>
            <Text ta="left" size="13px" mt={10}>
              Make sure this is the same information as on your Certificate of Incorporation and
              Registration so we can verify it later on.
            </Text>
          </Box>
          <Box>
            <Text ta="left" size="14px" fw={500}>
              Upload Affidavit stating the names of the group and the executive members
            </Text>
            <Text ta="left" size="11px" mt={8}>
              To verify the group and its representatives, please upload a clear and well-lit copy
              of your affidavit stating the group name and executive members.
            </Text>

            <FileUpload
              {...form.getInputProps('executiveMembersAffidavit')}
              onChange={(files) => handleFileUpload(files, 'executiveMembersAffidavit')}
              disabled={isLoading}
            />
          </Box>
          <Box mt={50}>
            <Text ta="left" size="14px" fw={500}>
              Upload Board Resolution Documents
            </Text>

            <Text ta="left" size="13px" mt={12}>
              This document should include:
              <List type="ordered" className="list-decimal" size="xs" mt={10}>
                <List.Item>Representatives are mandated to open the acct</List.Item>
                <List.Item>
                  Names of the representatives or signatories and their positions in the company.{' '}
                  <br /> For example president, secretary, member etc.
                </List.Item>
                <List.Item>
                  Mandate on operating the account (indicating if it is 1 or 2 signatories)
                </List.Item>
              </List>
            </Text>
            <FileUpload
              {...form.getInputProps('boardResolution')}
              onChange={(files) => handleFileUpload(files, 'boardResolution')}
              disabled={isLoading}
            />
          </Box>
          <Box mt={50}>
            <Text ta="left" size="14px" fw={500}>
              Upload the group constitution including its rules and regulations
            </Text>
            <Text ta="left" size="11px" mt={8}>
              Upload your group constitution, including rules and regulations, in a supported file
              format such as PDF, Word document, or JPG.
            </Text>
            <FileUpload
              {...form.getInputProps('groupConstitution')}
              onChange={(files) => handleFileUpload(files, 'groupConstitution')}
              disabled={isLoading}
            />
          </Box>
          <Box mt={50}>
            <Text ta="left" size="14px" fw={500}>
              Upload Utility bill for the group and individuals
            </Text>
            <Text ta="left" size="11px" mt={8}>
              Please upload a copy of your recent utility bill, ensuring all information, including
              your address and account number, is clearly visible.
            </Text>
            <FileUpload
              {...form.getInputProps('utilityBill')}
              onChange={(files) => handleFileUpload(files, 'utilityBill')}
              disabled={isLoading}
            />
          </Box>
          <Group justify="center" mt="xl">
            <Button
              bg="transparent"
              color="#0798D0"
              w={180}
              radius={12}
              mx={10}
              h={37}
              leftSection={<MdOutlineArrowBack size={16} />}
              variant="light"
              type="button"
              component="a"
              onClick={onPrevStep}>
              Back
            </Button>
            <Button
              variant="gradient"
              gradient={{ from: '#24E3F2', to: '#0798D0', deg: 90 }}
              w={180}
              radius={12}
              mx={10}
              type="submit"
              disabled={submit}>
              {submit ? <Loader size={20} /> : 'Save and Continue'}
            </Button>
          </Group>
        </form>
      </Box>
    </>
  );
};

export default NonRegisterDoc;
