import { useEffect } from 'react';
import { Navigate } from 'react-router';
import { useAuth } from '../../context/auth.context';
import DashLoader from '../dashboard/component/common/DashLoader';

const ProtectedRoutes: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { user, loading, getUser } = useAuth();

  // get user info on page refresh, for dashboard pages only...
  useEffect(() => {
    getUser();
  }, []);

  if (loading) {
    return <DashLoader />;
  }

  if (!user) return <Navigate to="/login" />;

  return <>{children}</>;
};

export default ProtectedRoutes;
