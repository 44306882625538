import { Center, Container, Image } from '@mantine/core';

export const Navbar = () => {
  return (
    <Container>
      <Center>
        <Image
          radius='md'
          h={90}
          my={60}
          w='auto'
          fit='contain'
          src='/assets/logo.png'
        />
      </Center>
    </Container>
  );
};
