import { Button, Grid, Loader, PinInput } from '@mantine/core';
import { yupResolver } from 'mantine-form-yup-resolver';
import { useState } from 'react';
import { useForm } from '@mantine/form';
import * as yup from 'yup';
import { apiTransferSingle } from '../../../../../api/ApiServices';
import { notifications } from '@mantine/notifications';
import { useApproval } from '../../../../../../context/approval.context';

type Prop = {
  onNext: () => void;
  onPrev: () => void;
  formData: Beneficiary;
};

const Step3 = ({ onPrev, onNext, formData }: Prop) => {
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const { getApprovalCount } = useApproval();

  const schema = yup.object().shape({
    pin: yup.number().min(4, 'At least 4 pin').required('Pin is required'),
  });

  const form = useForm({
    initialValues: {
      pin: '',
    },
    validate: yupResolver(schema),
  });

  // verify pin // 2fa
  const verifyPin = async (params: any) => {
    try {
      setLoading(true);
      const payload = {
        ...params,
        ...formData,
      };

      const resp = await apiTransferSingle(payload);
      onNext();
      setLoading(false);
    } catch (error: any) {
      setError(
        notifications.show({
          title: error?.data?.message || error?.status || 'Something went wrong',
          color: 'red',
          message: error?.message || 'An error occurred, please try again later.',
        })
      );
      // Clear the PIN field
      form.setFieldValue('pin', '');
      setLoading(false);
    } finally {
      // update approval count when transfer is initiated...
      await getApprovalCount();
    }
  };

  return (
    <>
      <section>
        <div className="text-center w-2/3 mx-auto">
          <h1>Enter your 2 factor authentication code to send the transaction for approval</h1>
          <form onSubmit={form.onSubmit(verifyPin)}>
            <PinInput
              length={6}
              className="w-3/4 mx-auto text-center my-5"
              type="number"
              {...form.getInputProps('pin')}
            />

            <Grid className="mt-12 mb-10">
              <Grid.Col span={6}>
                <Button
                  variant="outline"
                  fullWidth
                  radius={12}
                  type="button"
                  component="a"
                  onClick={onPrev}>
                  Back
                </Button>
              </Grid.Col>
              <Grid.Col span={6}>
                <Button
                  variant="gradient"
                  gradient={{ from: '#24E3F2', to: '#0798D0', deg: 90 }}
                  fullWidth
                  radius={12}
                  type="submit"
                  // onClick={onNext}
                  disabled={loading}>
                  {loading ? <Loader size={20} /> : 'Send'}
                </Button>
              </Grid.Col>
            </Grid>
          </form>
        </div>
      </section>
    </>
  );
};

export default Step3;
