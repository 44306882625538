import { Box, Button, Group, List, Loader, LoadingOverlay, Text } from '@mantine/core';
import { MdOutlineArrowBack } from 'react-icons/md';
import { useForm, yupResolver } from '@mantine/form';
import * as yup from 'yup';
import FileUpload from '../../common/FileUpload';
import { apiFileSubmit, apiUpload } from '../../../api/ApiServices';
import { useEffect, useState } from 'react';
import { notifications } from '@mantine/notifications';
import { fileUpload } from '../../common/fileUtils';

const RegisterDoc = ({ onNextStep, onPrevStep }: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [submit, setSubmit] = useState(false);

  const [step, setStep] = useState(() => {
    const savedStep = localStorage.getItem('currentStep');
    return savedStep ? parseInt(savedStep, 10) : 2;
  });

  useEffect(() => {
    localStorage.setItem('currentStep', step.toString());
  }, [step]);

  const schema = yup.object().shape({
    certificateOfIncorporation: yup
      .mixed()
      .required('Certificate Of Incorporation is required ') as yup.MixedSchema<File | null>,
    boardResolution: yup
      .mixed()
      .required('Board Resolution is required ') as yup.MixedSchema<File | null>,
    directorsParticular: yup
      .mixed()
      .required('Trustee particulars is required ') as yup.MixedSchema<File | null>,
    businessPowerOfAttorney: yup
      .mixed()
      .required('Power Of Attorney is required ') as yup.MixedSchema<File | null>,
    specializedLicense: yup
      .mixed()
      .required('Specialized License is required ') as yup.MixedSchema<File | null>,
  });

  const form = useForm({
    initialValues: {
      certificateOfIncorporation: null,
      boardResolution: null,
      directorsParticular: null,
      businessPowerOfAttorney: null,
      specializedLicense: null,
    },

    validate: yupResolver(schema),
  });

  const [payload, setPayload] = useState({});
 const handleFileUpload = (files: File[], key: string) => {
   fileUpload(files, key, form, setIsLoading, setPayload);
 };

 const submitLinks = async (event: any) => {
   try {
     setSubmit(true);
     const id = localStorage.getItem('registeredId') || '';
     const resp = await apiFileSubmit(id, payload);
     console.log(resp);
     onNextStep();
   } catch (error: any) {
     notifications.show({
       title: 'Something went wrong',
       color: 'red',
       message:
         error?.data?.message || error.message || 'An error occurred, please try again later.',
     });
   } finally {
     setSubmit(false);
   }
 };

  return (
    <>
      <Box pos="relative">
        <LoadingOverlay
          zIndex={1000}
          visible={isLoading}
          overlayProps={{ radius: 'sm', blur: 1 }}
        />

        <form onSubmit={form.onSubmit((e) => submitLinks(e))}>
          <Box mb={50} mt={20}>
            <Text ta="left" fw={500}>
              Provide us with the following Documents
            </Text>
            <Text ta="left" size="13px" mt={10}>
              Make sure this is the same information as on your Certificate of Incorporation and
              Registration so we can verify it later on.
            </Text>
          </Box>
          <Box>
            <Text ta="left" size="14px" fw={500}>
              Upload Certificate of Registration with CAC
            </Text>
            <Text ta="left" size="11px" mt={8}>
              For verification purposes, please upload a high-quality copy of your Certificate of
              Registration with CAC showing all relevant details.
            </Text>

            <FileUpload
              {...form.getInputProps('certificateOfIncorporation')}
              onChange={(files) => handleFileUpload(files, 'certificateOfIncorporation')}
              disabled={isLoading}
            />
          </Box>
          <Box mt={50}>
            <Text ta="left" size="14px" fw={500}>
              Upload Board Resolution Documents
            </Text>
            <Text ta="left" size="13px" mt={12}>
              This document should include:
            </Text>
            <List type="ordered" size="xs" mt={10}>
              <List.Item>Representatives are mandated to open the acct</List.Item>
              <List.Item>
                Names of the representatives or signatories and their positions in the company.{' '}
                <br /> For example president, secretary, member etc.
              </List.Item>
              <List.Item>
                Mandate on operating the account (indicating if it is 1 or 2 signatories)
              </List.Item>
            </List>

            <FileUpload
              {...form.getInputProps('boardResolution')}
              onChange={(files) => handleFileUpload(files, 'boardResolution')}
              disabled={isLoading}
            />
          </Box>
          <Box mt={50}>
            <Text ta="left" size="14px" fw={500}>
              Upload Form CO7 (Particulars of the trustee)
            </Text>
            <Text ta="left" size="11px" mt={8}>
              For verification purposes, please upload a high-quality copy of your Particulars of
              the trustee showing all relevant details.
            </Text>

            <FileUpload
              {...form.getInputProps('directorsParticular')}
              onChange={(files) => handleFileUpload(files, 'directorsParticular')}
              disabled={isLoading}
            />
          </Box>
          <Box mt={50}>
            <Text ta="left" size="14px" fw={500}>
              Upload Power of Attorney
            </Text>
            <Text ta="left" size="11px" mt={8}>
              For verification purposes, please upload a high-quality copy of your Power of Attorney
              showing all relevant details.
            </Text>

            <FileUpload
              {...form.getInputProps('businessPowerOfAttorney')}
              onChange={(files) => handleFileUpload(files, 'businessPowerOfAttorney')}
              disabled={isLoading}
            />
          </Box>
          <Box mt={50}>
            <Text ta="left" size="14px" fw={500}>
              Upload Supporting, Specialized licence (e.g specializedLicense)
            </Text>
            <Text ta="left" size="11px" mt={8}>
              For verification purposes, please upload a high-quality copy of Specialized licence
            </Text>

            <FileUpload
              {...form.getInputProps('specializedLicense')}
              onChange={(files) => handleFileUpload(files, 'specializedLicense')}
              disabled={isLoading}
            />
          </Box>

          <Group justify="center" mt="xl">
            <Button
              bg="transparent"
              color="#0798D0"
              w={180}
              radius={12}
              mx={10}
              h={37}
              leftSection={<MdOutlineArrowBack size={16} />}
              variant="light"
              type="button"
              component="a"
              onClick={onPrevStep}>
              Back
            </Button>
            <Button
              variant="gradient"
              gradient={{ from: '#24E3F2', to: '#0798D0', deg: 90 }}
              w={180}
              radius={12}
              mx={10}
              type="submit"
              disabled={submit}>
              {submit ? <Loader size={20} /> : 'Save and Continue'}
            </Button>
          </Group>
        </form>
      </Box>
    </>
  );
};

export default RegisterDoc;
