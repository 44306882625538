import style from '../../styles/style.module.css';
import RecentTransactionTab from '../fund-transfer/RecentTransactionTab';

const TransactionHistory = () => {
 
  return (
    <section className={`${style.main} px-12 pt-5 mb-10`}>
      <RecentTransactionTab />
    </section>
  );
};
export default TransactionHistory;
