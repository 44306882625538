import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Layout from './components/Layout';
import { Login } from './components/onboarding/auth/Login';
import { BusinessAccount } from './components/onboarding/business/BusinessAccount';
import { CooperateAccount } from './components/onboarding/cooperateAccount/CooperateAccount';
import ForgetPassword from './components/onboarding/forget-password/forgetPassword';
import NonRegisterAssociation from './components/onboarding/nonRegisteredAssociationAccount/NonRegisterAssociation';
import ResetPassword from './components/onboarding/passwordReset/RestPasword';
import RegisteredAssociation from './components/onboarding/registeredAssociationACccount/RegisteredAssociation';
import SoleProperietorship from './components/onboarding/soleProprietorshipAccount/SoleProperietorship';
import Dashboard from './components/dashboard/Dashboard';
import Approvals from './components/dashboard/component/approvals/Approvals';
import DashLayout from './components/dashboard/component/common/DashLayout';
import FundsTransfer from './components/dashboard/component/fund-transfer/FundsTransfer';
import Settings from './components/dashboard/component/settings/Settings';
import Support from './components/dashboard/component/support/Support';
import TransactionHistory from './components/dashboard/component/transaction-history/TransactionHistory';
import Account from './components/dashboard/component/bank-statement/Statement';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="*" element={<Navigate to="/login" replace />} />

        <Route
          path="/login"
          element={
            <Layout>
              <Login />
            </Layout>
          }
        />
        <Route
          path="/business"
          element={
            <Layout>
              <BusinessAccount />
            </Layout>
          }
        />
        <Route
          path="/sole-proprietorship"
          element={
            <Layout>
              <SoleProperietorship />
            </Layout>
          }
        />
        <Route
          path="/cooperate-account"
          element={
            <Layout>
              <CooperateAccount />
            </Layout>
          }
        />
        <Route
          path="/registered-association-account"
          element={
            <Layout>
              <RegisteredAssociation />
            </Layout>
          }
        />
        <Route
          path="/non-registered-association-account"
          element={
            <Layout>
              <NonRegisterAssociation />
            </Layout>
          }
        />

        <Route
          path="/reset-password"
          element={
            <Layout>
              <ResetPassword />
            </Layout>
          }
        />
        <Route
          path="/forget-password"
          element={
            <Layout>
              <ForgetPassword />
            </Layout>
          }
        />

        {/* dashboard routes */}
        <Route
          path="/dashboard"
          element={
            <DashLayout>
              <Dashboard />
            </DashLayout>
          }
        />
        <Route
          path="/dashboard/funds-transfer"
          element={
            <DashLayout>
              <FundsTransfer />
            </DashLayout>
          }
        />
        <Route
          path="/dashboard/transaction-history"
          element={
            <DashLayout>
              <TransactionHistory />
            </DashLayout>
          }
        />
        <Route
          path="/dashboard/approvals"
          element={
            <DashLayout>
              <Approvals />
            </DashLayout>
          }
        />
        <Route
          path="/dashboard/bank-statement"
          element={
            <DashLayout>
              <Account />
            </DashLayout>
          }
        />
        <Route
          path="/dashboard/settings"
          element={
            <DashLayout>
              <Settings />
            </DashLayout>
          }
        />
        <Route
          path="/dashboard/support"
          element={
            <DashLayout>
              <Support />
            </DashLayout>
          }
        />
        {/* Add more routes for other pages if needed */}
      </Routes>
    </Router>
  );
}

export default App;
