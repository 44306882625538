import { Anchor, Modal } from '@mantine/core';
import numeral from 'numeral';
import { apiTranReceipt } from '../../../../../api/ApiServices';
import { notifications } from '@mantine/notifications';
import { useState } from 'react';
import { Navigate, useNavigate } from 'react-router';
import { saveAs } from 'file-saver';

type Prop = {
  approvalData: Transaction[];
  isOpen: boolean;
  isApproval: boolean;
  onClose: () => void;
};

const Step3 = ({ approvalData, isApproval, isOpen, onClose }: Prop) => {
  const navigate = useNavigate();
  const roleName = localStorage.getItem('roleName') as RoleName | null;
  return (
    <Modal centered opened={isOpen} onClose={onClose} withCloseButton={false}>
      <section>
        <div className="text-center">
          {isApproval ? (
            roleName === 'checker' ? (
              <section>
                <div className="text-center ">
                  <img
                    src="/assets/success-img.png"
                    alt="success image"
                    className="w-2/4 mx-auto"
                  />
                  <h2 className="my-3 text-[#0798D0] font-bold">Successful</h2>
                  <h2 className="my-3">Transaction is sent for approval</h2>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      navigate('/dashboard/approvals');
                    }}
                    className="!text-black text-lg !underline mt-6 mb-3 cursor-pointer">
                    Take me to approval
                  </button>
                </div>
              </section>
            ) : (
              <>
                <img src="/assets/success-img2.png" alt="success image" className="w-2/4 mx-auto" />
                <h2 className="my-3 text-[#0798D0] font-bold">Money Sent</h2>
                {approvalData.length === 1 ? (
                  <h2 className="my-6 text-center mx-auto">
                    You sent{' '}
                    <span className="font-semibold">
                      {numeral(approvalData[0]?.amount || 0).format('0,0.00')} <br /> to{' '}
                      {approvalData[0].beneficiaryAccountName}
                    </span>
                  </h2>
                ) : null}
              </>
            )
          ) : (
            <>
              <img
                src="/assets/decline-transaction.png"
                alt="decline image"
                className="w-4/5 mx-auto my-10"
              />
              <h2 className="my- text-red-500 font-bold my-4">Transaction Declined</h2>
              <p className=" font-medium mb-6"> This transaction has been declined</p>
            </>
          )}
          <button
            onClick={() => {
              navigate('/dashboard');
            }}
            className="!text-black text-lg !underline mt-6 mb-3 cursor-pointer">
            Take me home
          </button>
        </div>
      </section>
    </Modal>
  );
};

export default Step3;
