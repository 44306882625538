import { useState } from 'react';
import { IIdleTimer, useIdleTimer } from 'react-idle-timer';

/**
 * @param onBeforeIdle - function to notify user when idle timeout is close
 * @param idleTime - number of minutes to wait before user is logged out
 * @param atIdle - function to be called when idle time has been reached
 */

export const prompt_timeout = 1000 * 60;

const useIdleTimeout = ({
  onBeforeIdle,
  idleTime,
  atIdle,
}: {
  onBeforeIdle: () => void;
  idleTime: number;
  atIdle: () => void;
}) => {
  const idleTimeout = 1000 * 60 * idleTime;
  const [isIdle, setIdle] = useState(false);

  const handleIdle = () => {
    setIdle(true);
    atIdle();
  };

  const idleTimer = useIdleTimer({
    timeout: idleTimeout,
    promptBeforeIdle: prompt_timeout,
    onPrompt: onBeforeIdle,
    onIdle: handleIdle,
    onAction: (event?: Event, idleTimer?: IIdleTimer) => {
      idleTimer?.reset();
    },
    debounce: 500,
  });

  return {
    isIdle,
    idleTimer,
  };
};

export default useIdleTimeout;
