import { Card, Container, BackgroundImage, Text, Flex, Stepper } from '@mantine/core';

import 'react-form-wizard-component/dist/style.css';
import { SetStateAction, useEffect, useState } from 'react';
import SuccessMessage from '../common/SuccessMessage';
import EmailCode from '../common/EmailCode';
import BusinessOwnerRep from './component/BusinessOwnerRep';
import BusinessSignatories from './component/BusinessSignatories';
import DocUpload from './component/DocUpload';
import RegisterStepOne from './component/RegisterStepOne';

const SoleProperietorship = () => {
  const [active, setActive] = useState(0);
  const [showSuccess, setShowSuccess] = useState(false);
  const [formData, setFormData] = useState({});
  const [step, setStep] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const steps = [
    'RegisterStepOne',
    'EmailCode',
    'DocUpload',
    'BusinessOwnerRep',
    'BusinessSignatories',
    'SuccessMessage',
  ];

  const nextStep = () => setActive((current) => Math.min(current + 1, steps.length - 1));
  const prevStep = () => setActive((current) => Math.max(current - 1, 0));

  const handleNextStep = () => {
    nextStep();
    setStep((prevStep) => prevStep + 1);
  };

  const updateFormData = (newData: SetStateAction<{}>) => {
    setFormData((prevFormData) => ({ ...prevFormData, ...newData }));
  };

  const handleFinishClick = () => {
    if (active === steps.length - 2) {
      setShowSuccess(true);
      console.log('form data here',formData);
    }
  };

  useEffect(() => {
    const storedStep = parseInt(localStorage.getItem('stepperStep') || '');
    console.log('Retrieved stepperStep from localStorage:', storedStep);
    setActive(!isNaN(storedStep) ? storedStep : 0);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    console.log('Setting stepperStep in localStorage:', active);
    localStorage.setItem('stepperStep', String(active));
  }, [active]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Container px={0}>
        <Card shadow="md" padding="xl" radius="md" maw={650} mx="auto">
          <Card.Section mx={-60}>
            <BackgroundImage src="/assets/form-bg.png" h={90}>
              <Flex
                direction={{ base: 'column', sm: 'row' }}
                gap={40}
                justify={{ sm: 'center' }}
                pt={5}>
                <Text size="lg" c="white" mt={10} className="text-center">
                  Let’s get you started with a
                  <span className="block font-[700]">Source Business Account</span>
                </Text>
              </Flex>
            </BackgroundImage>
          </Card.Section>

          {showSuccess ? (
            <>
              <SuccessMessage />
            </>
          ) : (
            <>
              <Stepper active={active} size="xs" mt={35} mb={40} onStepClick={setActive}>
                <Stepper.Step disabled>
                  <RegisterStepOne onNextStep={handleNextStep} updateFormData={updateFormData} />
                </Stepper.Step>
                <Stepper.Step disabled>
                  <EmailCode
                    onPrevStep={prevStep}
                    formData={formData}
                    onNextStep={handleNextStep}
                  />
                </Stepper.Step>
                <Stepper.Step disabled>
                  <DocUpload onPrevStep={prevStep} onNextStep={handleNextStep} />
                </Stepper.Step>
                <Stepper.Step disabled>
                  <BusinessOwnerRep onPrevStep={prevStep} onNextStep={handleNextStep} />
                </Stepper.Step>
                <Stepper.Step disabled>
                  <BusinessSignatories
                    onPrevStep={prevStep}
                    handleFinishClick={handleFinishClick}
                  />
                </Stepper.Step>
              </Stepper>
            </>
          )}
        </Card>
      </Container>
    </>
  );
};
export default SoleProperietorship;
