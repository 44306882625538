import {
  Grid,
  TextInput,
  Textarea,
  Radio,
  Group,
  Text,
  Select,
  Button,
  Loader,
  LoadingOverlay,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { yupResolver } from 'mantine-form-yup-resolver';
import React, { useRef, useState } from 'react';
import { MdOutlineArrowBack } from 'react-icons/md';
import * as yup from 'yup';
import { DateInput } from '@mantine/dates';
import dayjs from 'dayjs';
import { notifications } from '@mantine/notifications';
import { apiRegister } from '../../../api/ApiServices';
import ReCAPTCHA from 'react-google-recaptcha';
import Recaptcha, { verifyRecaptcha } from '../../common/Recaptcha';

const RegisterStepOne = ({ onNextStep, updateFormData }: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const recaptcha = useRef<ReCAPTCHA | null>(null);

  const register = async (params: any) => {
     let reCaptchaToken = '';

     try {
       reCaptchaToken = verifyRecaptcha(recaptcha);
     } catch (error: any) {
       notifications.show({
         title: 'reCAPTCHA failed',
         message: error?.message || JSON.stringify(error) || 'Please try the reCAPTCHA again',
         color: 'red',
       });
       return;
     }

     try {
       setIsLoading(true);
       updateFormData(params);
       let type = 'sole_proprietorship_account';

       const payload = {
         type,
         reCaptchaToken,
         meta: {},
       } as any;

       Object.entries(params).forEach(([key, value]) => {
         const keySplit = key.split('meta_');
         const payloadKey = keySplit[1] || keySplit[0];
         if (payloadKey === 'dateOfIncorporation') {
           value = dayjs(value as string).format('YYYY-MM-DD');
         }
         if (keySplit[1]) {
           payload.meta[payloadKey] = value;
         } else {
           payload[payloadKey] = value;
         }
       });

       const resp = await apiRegister(payload);
       localStorage.setItem('registeredId', resp.data._id);
       onNextStep();
       localStorage.setItem(`registerStepOne${step}`, JSON.stringify(params));
       console.log(resp, params, payload);
       setIsLoading(false);
     } catch (error: any) {
       console.log(error);
       setError(
         notifications.show({
           title: 'Something went wrong',
           color: 'red',
           message:
             error?.data?.message || error.message || 'An error occurred, please try again later.',
         })
       );
     } finally {
       setIsLoading(false);
     }
  };

  React.useEffect(() => {
    console.log('onload');
    const saved = localStorage.getItem(`registerStepOne${step}`);
    if (saved) {
      const jsonSaved = JSON.parse(saved);
      console.log(jsonSaved);
      form.setValues({
        ...jsonSaved,
        meta_dateOfIncorporation: new Date(jsonSaved.meta_dateOfIncorporation),
      });
    } else {
    }
  }, []);

  const schema = yup.object().shape({
    name: yup.string().required('Company Name is required'),
    meta_bnNumber: yup.string().required('RC Number is required'),
    meta_dateOfIncorporation: yup.date().required('Date of Incorporation is required'),
    nature: yup.string().required('Type/Nature of Business is required'),
    industry: yup.string().required('Sector/Industry is required'),
    phoneNumber: yup.string().required('Company phone number is required'),
    address: yup.string().required('Company Address is required'),
    email: yup.string().required('Company email address is required').email('Invalid email'),
    website: yup.string().url('Invalid website URL').optional(),
    meta_city: yup.string().required('City is required'),
    meta_country: yup.string().required('Country is required'),
    meta_taxIdNumber: yup.string().required('Tax Identification Number is required'),
    meta_crmNumber: yup.number().required('CRM No/Borrower’s Code is required'),
    meta_scumlRegNumber: yup
      .string()
      .optional()
      .matches(/^(|\d)+$/, 'SCUML Registration number must be digits'),
    meta_annualTurnover: yup.string().required('Annual Turnover is required'),
  });
  const form = useForm({
    initialValues: {
      name: '',
      meta_bnNumber: '',
      meta_dateOfIncorporation: '',
      nature: '',
      industry: '',
      phoneNumber: '',
      meta_city: '',
      meta_country: '',
      address: '',
      website: '',
      email: '',
      meta_taxIdNumber: '',
      meta_crmNumber: '',
      meta_scumlRegNumber: '',
      meta_annualTurnover: '',
    },

    validate: yupResolver(schema),
  });

  const [active, setActive] = useState(0);
  const [step, setStep] = useState(0);
  const prevStep = () => setActive((current) => Math.max(current - 1, 0));

  const handleBackClick = () => {
    if (active === 0) {
      window.history.back();
    } else {
      prevStep();
    }
  };

  return (
    <>
      <Text size="lg" mt={20} fw={500} ta="left">
        Tell us about your business
      </Text>
      <Text size="sm" ta="left">
        Make sure this is the same information as on your Certificate of Incorporation and
        Registration so we can verify it later on.
      </Text>
      <form onSubmit={form.onSubmit(register)}>
        <Grid mt={20} mb={60} grow>
          <Grid.Col span={6}>
            <TextInput
              ta="left"
              label="Company Name"
              withAsterisk
              radius={5}
              px={0}
              my={10}
              placeholder=""
              {...form.getInputProps('name')}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              ta="left"
              label="BN Number"
              withAsterisk
              radius={5}
              px={0}
              my={10}
              placeholder=""
              {...form.getInputProps('meta_bnNumber')}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <DateInput
              ta="left"
              valueFormat="YYYY-MM-DD"
              label="Date of Incorporation"
              withAsterisk
              clearable
              placeholder="DD-MM-YYYY"
              {...form.getInputProps('meta_dateOfIncorporation')}
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <TextInput
              ta="left"
              label="Type/Nature of Business"
              withAsterisk
              radius={5}
              px={0}
              my={10}
              placeholder=" "
              {...form.getInputProps('nature')}
            />
          </Grid.Col>
          {/* dropdown */}
          <Grid.Col span={6}>
            <Select
              ta="left"
              my={10}
              label="Sector/Industry"
              withAsterisk
              placeholder="Pick value"
              defaultValue="Information Technology"
              data={['Information Technology', 'Health', 'Others']}
              {...form.getInputProps('industry')}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              ta="left"
              label="Company phone number"
              withAsterisk
              radius={5}
              px={0}
              my={10}
              placeholder=""
              type="number"
              {...form.getInputProps('phoneNumber')}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              ta="left"
              label="City"
              withAsterisk
              radius={5}
              px={0}
              my={10}
              placeholder=""
              {...form.getInputProps('meta_city')}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <Select
              ta="left"
              label="Country"
              withAsterisk
              my={10}
              placeholder="Pick value"
              defaultValue="Country"
              data={[
                'Nigeria',
                'United States',
                'United Kingdom',
                'Canada',
                'Australia',
                'Germany',
                'France',
                'Italy',
                'South Africa',
              ]}
              {...form.getInputProps('meta_country')}
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <Textarea
              ta="left"
              label="Company Address"
              withAsterisk
              radius={5}
              px={0}
              my={10}
              placeholder=""
              {...form.getInputProps('address')}
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <TextInput
              ta="left"
              label="Company email address"
              withAsterisk
              radius={5}
              px={0}
              my={10}
              placeholder=" "
              {...form.getInputProps('email')}
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <TextInput
              ta="left"
              label="Company website (Optional)"
              radius={5}
              px={0}
              my={10}
              placeholder=" "
              {...form.getInputProps('website')}
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <TextInput
              ta="left"
              label="Tax Identification Number (TIN)"
              withAsterisk
              radius={5}
              px={0}
              my={10}
              placeholder=" "
              {...form.getInputProps('meta_taxIdNumber')}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              ta="left"
              label="CRM No/Borrower’s Code"
              withAsterisk
              radius={5}
              px={0}
              my={10}
              placeholder=" "
              type="number"
              {...form.getInputProps('meta_crmNumber')}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              ta="left"
              label="SCUML Reg. No"
              radius={5}
              px={0}
              my={10}
              placeholder=" "
              type="string"
              {...form.getInputProps('meta_scumlRegNumber')}
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <Radio.Group
              ta="left"
              name="AnnualTurnover"
              label="Annual Turnover"
              withAsterisk
              {...form.getInputProps('meta_annualTurnover')}>
              <Group display="block" mt="xs">
                <Radio value="Less than N50million" label="Less than N50million" my={10} />
                <Radio
                  value="N50 million – Less than N500 million"
                  label="N50 million – Less than N500 million"
                  my={10}
                />
                <Radio
                  value="N500 million - Less than N5 billion"
                  label="N500 million - Less than N5 billion"
                  my={10}
                />
                <Radio value="Above N5 billion" label="Above N5 billion" my={10} />
              </Group>
            </Radio.Group>
          </Grid.Col>
        </Grid>

        <Recaptcha recaptcha={recaptcha} />

        <Group justify="center" mt="xl">
          <Button
            bg="transparent"
            color="#0798D0"
            w={180}
            radius={12}
            mx={10}
            h={37}
            leftSection={<MdOutlineArrowBack size={16} />}
            variant="light"
            type="submit"
            component="a"
            onClick={handleBackClick}>
            Back
          </Button>
          <Button
            variant="gradient"
            gradient={{ from: '#24E3F2', to: '#0798D0', deg: 90 }}
            w={180}
            radius={12}
            mx={10}
            type="submit"
            disabled={isLoading}>
            {isLoading ? <Loader size={20} /> : 'Save and Continue'}
          </Button>
        </Group>
      </form>
    </>
  );
};

export default RegisterStepOne;
