import {
  Grid,
  Select,
  TextInput,
  Group,
  Button,
  Loader,
  Box,
  LoadingOverlay,
  NumberInput,
} from '@mantine/core';
import { yupResolver } from '@mantine/form';
import { useState, useEffect } from 'react';
import { useForm } from '@mantine/form';
import * as yup from 'yup';
import { apiGetAccountName, apiGetBanks, apiGetCategories } from '../../../../../api/ApiServices';
import { notifications } from '@mantine/notifications';
import { useAccount } from '../../../../../../context/account.context';

type Prop = {
  onNext: () => void;
  onFormDataChange: (data: Beneficiary) => void;
  formData: Beneficiary;
};

const Step1 = ({ onNext, onFormDataChange, formData }: Prop) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [banks, setBanks] = useState<Bank[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [accountName, setAccountName] = useState<string>('');
  const [bankCode, setBankCode] = useState<string>('');
  const [nameEnquiryRef, setNameEnquiryRef] = useState<string>('');
  const { accounts, currentAccount } = useAccount();

  const schema = yup.object().shape({
    originatorAccountNumber: yup.string().required('Account Type is required'),
    bankName: yup.string().required('Bank Name is required'),
    accountName: yup.string().required('Recipient name is required'),
    accountNumber: yup
      .string()
      .required('Recipient account number is required')
      .matches(/^\d{10}$/, 'Account number must be 10 digits'),
    amount: yup.string().required('Amount is required'),
    narration: yup.string().optional(),
    category: yup.string().optional(),
  });

  const form = useForm({
    initialValues: formData,
    validate: yupResolver(schema),
  });

  const handleNext = () => {
    const payload = { ...form.values, bankCode, nameEnquiryRef };
    onFormDataChange(payload);
    onNext();
  };
  // Fetch banks
  const fetchBanks = async () => {
    try {
      const resp = await apiGetBanks();
      setBanks(resp.data.banks);
    } catch (error: any) {
      setError(error?.message || error?.data?.message || 'Something went wrong');

      notifications.show({
        title: error?.message || error?.data?.message || 'Something went wrong',
        color: 'red',
        message: error?.message || 'An error occurred, please try again later.',
      });
    }
  };

  useEffect(() => {
    fetchBanks();
  }, []);

  // Fetch categories
  const fetchCategories = async () => {
    try {
      const resp = await apiGetCategories();
      setCategories(resp.data.categories);
    } catch (error: any) {
      setError(error?.message || error?.data?.message || 'Something went wrong');
      notifications.show({
        title: error?.message || error?.data?.message || 'Something went wrong',
        color: 'red',
        message: error?.message || 'An error occurred, please try again later.',
      });
    }
  };

  useEffect(() => {
    if (currentAccount) {
      form.setValues({ originatorAccountNumber: currentAccount.number });
    } else if (accounts.length > 0) {
      form.setValues({ originatorAccountNumber: accounts[0]?.number });
    } else {
      form.setValues({ originatorAccountNumber: '' });
    }
    fetchCategories();
  }, []);

  // Fetch account name based on bank and account number
  const fetchAccountName = async (bankCode: string, accountNumber: string) => {
    setIsLoading(true);
    setAccountName('');
    try {
      const payload = {
        bankCode: bankCode,
        accountNumber: accountNumber,
      };
      const resp = await apiGetAccountName(payload);
      setBankCode(bankCode);
      setNameEnquiryRef(resp.data.nameEnquiryRef);
      console.log(resp, 'nameEnquiryRef');
      setAccountName(resp.data.accountName);
      form.setFieldValue('accountName', resp.data.accountName);
    } catch (error: any) {
      notifications.show({
        title: 'Name Enquiry',
        color: 'red',
        message: error?.message || 'An error occurred, please try again later.',
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const accountNumber = form.values.accountNumber;
    if (accountNumber && accountNumber.length === 10) {
      const selectedBank = banks.find((bank) => bank.name === form.values.bankName);
      if (selectedBank && selectedBank.code) {
        fetchAccountName(selectedBank.code, accountNumber);
      } else {
        setAccountName('');
        form.setFieldValue('accountName', '');
        setBankCode('');
      }
    } else {
      setAccountName('');
      form.setFieldValue('accountName', '');
      setBankCode('');
    }
  }, [form.values.bankName, form.values.accountNumber, banks]);

  return (
    <Box pos="relative">
      <LoadingOverlay zIndex={1000} visible={isLoading} overlayProps={{ radius: 'sm', blur: 1 }} />
      <div>
        <h2 className="font-medium">Bank details</h2>
        <form onSubmit={form.onSubmit(handleNext)}>
          <Grid mt={20} mb={60} grow>
            <Grid.Col span={12}>
              <Select
                ta="left"
                my={20}
                searchable
                label="Select account"
                withAsterisk
                placeholder="Pick value"
                value={currentAccount ? `${currentAccount?.name} (${currentAccount?.number})` : ''}
                data={accounts.map((account) => ({
                  value: account?.number,
                  label: `${account?.name} (${account?.number})`,
                }))}
                {...form.getInputProps('originatorAccountNumber')}
                // value={allAccount.length > 0 ? allAccount[0]?.number : ''}
              />

              <Select
                ta="left"
                my={20}
                searchable
                label="Recipient bank"
                withAsterisk
                placeholder="Pick value"
                data={banks.map((bank) => ({ value: bank.name, label: bank.name }))}
                {...form.getInputProps('bankName')}
              />
              <TextInput
                ta="left"
                label="Recipient account number"
                type="text"
                withAsterisk
                radius={5}
                px={0}
                my={20}
                placeholder=""
                {...form.getInputProps('accountNumber')}
                onChange={(event) => {
                  const regexp = /^\d+$/;
                  const numberValue = event.target.value;

                  // if it's not a number
                  if (!regexp.test(numberValue) && numberValue !== '') return;

                  form.setFieldValue('accountNumber', numberValue);

                  if (numberValue.length !== 10) {
                    setAccountName('');
                    form.setFieldValue('accountName', '');
                  }
                }}
              />
              <TextInput
                ta="left"
                label="Recipient name"
                withAsterisk
                radius={5}
                px={0}
                my={20}
                value={accountName}
                disabled
                placeholder={isLoading ? 'Loading...' : ''}
                {...form.getInputProps('accountName')}
              />
              <NumberInput
                ta="left"
                label="Amount"
                withAsterisk
                radius={5}
                px={0}
                my={20}
                thousandSeparator=","
                placeholder=""
                {...form.getInputProps('amount')}
              />
              <TextInput
                ta="left"
                label="What are you sending money for?"
                radius={5}
                px={0}
                my={20}
                placeholder=""
                {...form.getInputProps('narration')}
              />
              <Select
                ta="left"
                mt={20}
                label="Payment Category"
                placeholder="Pick value"
                data={categories.map((category) => ({
                  value: category.name,
                  label: category.name,
                }))}
                {...form.getInputProps('category')}
              />
            </Grid.Col>
          </Grid>
          <Group justify="center" mt="xl">
            <Button
              variant="gradient"
              gradient={{ from: '#24E3F2', to: '#0798D0', deg: 90 }}
              fullWidth
              radius={12}
              mb={10}
              mx={20}
              type="submit"
              disabled={isLoading}>
              {isLoading ? <Loader size={20} /> : 'Next'}
            </Button>
          </Group>
        </form>
        {error && <div style={{ color: 'red' }}>{error}</div>}
      </div>
    </Box>
  );
};

export default Step1;
