import { Button, Grid } from '@mantine/core';
import numeral from 'numeral';
import { getCBNTransferFee } from '../../../fund-transfer/component/modal/Step2';

type Prop = {
  approvalData: Transaction[];
  onApprove: () => void;
  onReject: () => void;
};

const Step1 = ({ approvalData, onApprove, onReject }: Prop) => {
  const amount = Number(approvalData[0]?.amount || 0);
  const fee = amount > 0 ? getCBNTransferFee(amount, approvalData[0].beneficiaryBankCode) : 0;
  const total = amount + fee;

  const roleName = localStorage.getItem('roleName') as RoleName | null;

  // transaction verified by the checker but awaiting authoriser approval...
  const checkedPendingAuthoriser =
    roleName === 'checker' && approvalData[0].approvalStatus === 'awaiting_authoriser';

  return (
    <section className="p-2">
      <div className="flex justify-between mt-6 mb-8">
        <h1 className="">Requested by</h1>
        <span className="font-medium">
          {`${approvalData[0]?.initiator.firstName} ${approvalData[0]?.initiator.lastName}`}
        </span>
      </div>
      <hr className="font-100 mb-6" />

      <div className="flex justify-between mt-5">
        <p className="text-sm ">Amount to send</p>
        <h1 className=" font-medium">₦{numeral(amount).format('0,0.00')}</h1>
      </div>
      <div className="flex justify-between mt-2">
        <p className="text-sm ">Transaction fee</p>
        <h1 className="text-sm">₦{numeral(fee).format('0,0.00')}</h1>
      </div>
      <div className="flex justify-between mt-2">
        <p className="text-sm ">Total</p>
        <h1 className="text-sm">₦{numeral(total).format('0,0.00')}</h1>
      </div>
      <hr className="font-100 my-6" />

      <div className="flex justify-between mt-5">
        <p className="text-sm ">Bank</p>
        <p className="text-sm">{approvalData[0]?.beneficiaryBankName}</p>
      </div>
      <div className="flex justify-between mt-3">
        <p className="text-sm ">Account number</p>
        <p className="text-sm">{approvalData[0]?.beneficiaryAccountNumber}</p>
      </div>
      <div className="flex justify-between mt-3">
        <p className="text-sm ">Account name</p>
        <p className="text-sm">{approvalData[0]?.beneficiaryAccountName}</p>
      </div>
      <div className="flex justify-between mt-3">
        <p className="text-sm ">Narration</p>
        <p className="text-sm">{approvalData[0]?.narration || ''}</p>
      </div>
      {/* <hr className="font-bold my-6" /> */}
      {/* <div className="flex justify-between">
            <h1 className="font-semibold">Schedule Details</h1>
            <Anchor href="#" className="text-blue-400 !underline">
              Edit
            </Anchor>
          </div>
          <div className="flex justify-between mt-5">
            <p className="text-sm ">Send date</p>
            <p className="text-sm">05/08/2024</p>
          </div>
          <div className="flex justify-between mt-3">
            <p className="text-sm ">Should arrive</p>
            <p className="text-sm">Instantly</p>
          </div> */}
      {roleName === 'initiator' ||
      checkedPendingAuthoriser ||
      approvalData[0]?.approvalStatus === 'approved' ||
      approvalData[0]?.approvalStatus === 'rejected' ? null : (
        <Grid className="mt-12 mb-10">
          <Grid.Col span={6}>
            <Button
              variant="outline"
              fullWidth
              radius={12}
              type="button"
              component="a"
              onClick={onReject}>
              Decline
            </Button>
          </Grid.Col>
          <Grid.Col span={6}>
            <Button
              variant="gradient"
              gradient={{ from: '#24E3F2', to: '#0798D0', deg: 90 }}
              fullWidth
              radius={12}
              type="submit"
              onClick={onApprove}>
              Approve
            </Button>
          </Grid.Col>
        </Grid>
      )}
    </section>
  );
};

export default Step1;
