import ReCAPTCHA from 'react-google-recaptcha';
// import { apiVerifyRecaptcha } from '../../api/ApiServices';
import { FaInfo } from 'react-icons/fa';

export const verifyRecaptcha = (recaptcha: React.MutableRefObject<ReCAPTCHA | null>) => {
  let message = 'Please complete the reCAPTCHA verification before proceeding';

  if (recaptcha.current) {
    const recaptchaValue = recaptcha.current.getValue();

    if (!recaptchaValue) {
      message = 'reCAPTCHA required';
      throw message;
    } else {
      recaptcha.current.reset();
      return recaptchaValue;
    }
    
  } else {
    message = 'There was an error loading the reCAPTCHA. Please try refreshing the page.';
    throw message;
  }
};

export default function Recaptcha({
  recaptcha,
}: {
  recaptcha: React.MutableRefObject<ReCAPTCHA | null>;
}) {
  const siteKey = process.env.REACT_APP_SITE_KEY || '';
  const isLive = process.env.NODE_ENV === 'production';

  return (
    <div className="flex justify-center items-center w-full ">
      {siteKey ? (
        <ReCAPTCHA sitekey={siteKey} ref={recaptcha} />
      ) : (
        <div className="flex justify-between gap-4 items-center bg-red-600 text-white text-xs px-3 py-5 rounded-lg">
          <FaInfo className="text-white rounded-full text-sm" />
          <span>
            {!isLive
              ? 'Error: reCAPTCHA site key is missing. Please check your environment variables.'
              : 'There was an error loading the reCAPTCHA. Please try refreshing the page.'}
          </span>
        </div>
      )}
    </div>
  );
}
