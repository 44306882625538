import { Container, Button, Card, TextInput, Group, Loader, Flex } from '@mantine/core';
import { useForm } from '@mantine/form';
import { yupResolver } from 'mantine-form-yup-resolver';
import { useState } from 'react';
import * as yup from 'yup';
import { notifications } from '@mantine/notifications';
import { apiForgetPassword } from '../../api/ApiServices';
import { MdOutlineArrowBack } from 'react-icons/md';

const ForgetPassword = () => {
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [linksent, setLinkSent] = useState<string | null>(null);

  const schema = yup.object().shape({
    email: yup.string().required('Email address is required').email('Invalid email'),
  });

  const form = useForm({
    initialValues: {
      email: '',
    },

    validate: yupResolver(schema),
  });

  // reset link sent to email
  const forgetPassword = async (params: any) => {
    try {
      setIsLoading(true);
      const payload = {
        ...params,
      };
      const resp = await apiForgetPassword(payload);
      setLinkSent(
        notifications.show({
          title: 'Successful',
          color: 'green',
          message: 'Password rest link has been sent to your email',
        })
      );
      setTimeout(() => {
        window.location.href = '/login';
      }, 4000);
      setIsLoading(false);
      console.log(payload);
    } catch (error: any) {
      setError(
        notifications.show({
          title: 'Something went wrong',
          color: 'red',
          message: error?.data?.message || 'An error occurred, please try again later.',
        })
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Container px={0}>
        <Card shadow="md" padding="xl" radius="md" maw={450} mx="auto" p={60}>
          <h1 className="font-medium my-4 text-2xl">Forgot Password?</h1>
          <small>Provide the email address linked to your account. </small>
          <form onSubmit={form.onSubmit(forgetPassword)}>
            <TextInput
              withAsterisk
              radius={12}
              my={20}
              placeholder="Email address"
              {...form.getInputProps('email')}
            />
            <Group justify="center" mt="xl">
              <Flex justify="space-between">
                <Button
                  bg="transparent"
                  color="#0798D0"
                  // w={180}
                  radius={12}
                  mx={10}
                  h={37}
                  leftSection={<MdOutlineArrowBack size={16} />}
                  variant="light"
                  type="button"
                  component="a"
                  href="/login">
                  Back
                </Button>
                <Button
                  variant="gradient"
                  gradient={{ from: '#24E3F2', to: '#0798D0', deg: 90 }}
                  w={130}
                  radius={12}
                  mx={10}
                  type="submit"
                  disabled={isLoading}>
                  {isLoading ? <Loader size={20} /> : 'Continue'}
                </Button>
              </Flex>
            </Group>
          </form>
        </Card>
      </Container>
    </>
  );
};

export default ForgetPassword;
