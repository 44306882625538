import { Button, Tooltip } from '@mantine/core';
import { useEffect, useState } from 'react';
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi2';

interface PaginationProps {
  paging: Paging;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}

export default function Pagination({ paging, setPage, page }: PaginationProps) {
  const [goTo, setGoTo] = useState('');
  const [error, setError] = useState('');
  useEffect(() => {
    setGoTo(`${page}`);
  }, [page]);

  if (paging.pages <= 1) return <></>;

  return (
    <section className="flex justify-end items-center gap-4 my-4">
      <Button
        onClick={() => {
          if (paging.previous) setPage(paging.previous);
        }}
        disabled={paging.previous === null}>
        <HiChevronLeft className="text-xl" />
      </Button>
      <Tooltip label={error} opened={error !== ''} color="red" offset={5}>
        <main className="flex justify-start items-center gap-2">
          <input
            type="text"
            id="page"
            value={goTo}
            className={`w-8 h-8 font-semibold p-2 text-center outline-none ${
              error ? 'border m-1 border-red-300' : ''
            }`}
            onChange={(event) => {
              const value = event.target.value;
              setGoTo(value);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && goTo) {
                const regexp = /^\d+$/;
                // check that it's a valid page number...
                if (regexp.test(goTo) && +goTo <= paging.pages && +goTo >= 1) {
                  setError('');
                  setPage(+goTo);
                  setGoTo(goTo);
                } else {
                  setError('Please provide a valid page number');
                  setGoTo(String(page));
                }
              }
            }}
            onBlur={() => {
              setGoTo(String(page));
              setError('');
            }}
          />
          <span className="font-bold">/</span>
          <div className="font-bold">{paging.pages}</div>
        </main>
      </Tooltip>

      <Button
        onClick={() => {
          if (paging.next) setPage(paging.next);
        }}
        disabled={paging.next === null}>
        <HiChevronRight className="text-xl" />
      </Button>
    </section>
  );
}
