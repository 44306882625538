import React from 'react';
import { FaSpinner } from 'react-icons/fa';

export const GlassComponent = ({
  children,
  styles,
}: {
  styles?: string;
  children?: React.ReactNode;
}) => {
  return (
    <div
      className={`h-full w-full bg-gray-400 rounded-md bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-30 border border-gray-100 ${styles}`}>
      {children}
    </div>
  );
};

export default function DashLoader() {
  return (
    <section className="min-h-dvh">
      <div className="min-h-dvh grid grid-cols-12 grid-rows-12 gap-2">
        <header className="col-span-12 row-span-2 bg-black">
          <div className="px-6 w-full h-full flex justify-between items-center">
            <img
              src="/assets/dash/dash-logo.png"
              className="w-32 h-12 md:w-40 md:h-16"
              alt="source logo"
            />
            <FaSpinner className="animate-spin h-5 w-5 md:h-7 md:w-7 text-white" />
          </div>
        </header>
        <aside className="hidden md:block h-full col-span-2 row-span-10 animate-pulse p-2">
          <GlassComponent />
        </aside>
        <main className="col-span-12 h-full md:col-span-10 row-span-10 animate-pulse p-2">
          <GlassComponent />
        </main>
      </div>
    </section>
  );
}
